import { AsyncPipe, NgStyle } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { IonButton, IonCard } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { CourseType } from '@shared/content-api-interface';
import { isColorContrastLight, setCourseBackgroundColor } from '@shared/utils/typescript';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CircleProgressOptions } from 'ng-circle-progress/lib/ng-circle-progress.component';
import { CourseStatus } from '../../store/course/course.model';
import { selectContentUnlocked, selectIsBasicPlan } from '../../store/payment/store/payment.selectors';
import { showCourseInformationModal } from '../course-information-modal/course-information-modal.actions';
import { CourseWithProgress } from '../models/courses.models';

@Component({
    selector: 'app-course-slider-tile',
    templateUrl: './course-slider-tile.component.html',
    styleUrl: './course-slider-tile.component.scss',
    standalone: true,
    imports: [IonCard, IonButton, NgStyle, AnalyticsTrackClickDirective, NgCircleProgressModule, AsyncPipe],
})
export class CourseSliderTileComponent {
    @Input() backgroundColor = 'harley';
    @Input({ required: true }) course!: CourseWithProgress;

    private readonly store = inject(Store);

    public unlocked = this.store.selectSignal(selectContentUnlocked);
    public isBasicPlan = this.store.selectSignal(selectIsBasicPlan);

    public setBackground() {
        return {
            ...setCourseBackgroundColor(this.course.color),
            'background-image': isColorContrastLight(this.course.color)
                ? 'url(/assets/images/squiggles/doodle.png)'
                : 'url(/assets/images/squiggles/doodle-dark.png)',
        };
    }

    public openCourseInformationModal(course: CourseWithProgress): void {
        this.store.dispatch(showCourseInformationModal({ course }));
    }

    public getCircleProgressOptions(course: CourseWithProgress): CircleProgressOptions {
        const title =
            course.progress < 100 && course.status !== CourseStatus.COMPLETED ? course.progress.toString() : '✓';
        const titleColor =
            course.status === CourseStatus.COMPLETED ? `var(--ion-color-${course.color})` : 'var(--ion-color-jack)';
        const titleFontSize = course.progress < 100 ? '15' : '25';
        const showUnits = course.progress < 100;
        const percent = course.status !== CourseStatus.COMPLETED ? course.progress : 0;

        let outerStrokeColor;

        if (course.status === CourseStatus.COMPLETED) {
            outerStrokeColor = `var(--ion-color-${course.color})`;
        } else if (course.type === CourseType.PRE_PUP) {
            outerStrokeColor = 'var(--ion-color-bella)';
        } else {
            outerStrokeColor = 'var(--ion-color-max)';
        }

        const innerStrokeColor = `var(--ion-color-${course.color})`;

        return {
            showTitle: true,
            title,
            titleColor,
            titleFontSize,

            showSubtitle: false,

            clockwise: true,
            startFromZero: false,
            radius: 30,

            showUnits,
            units: '%',
            percent,
            toFixed: 0,
            unitsFontSize: 'medium',

            showBackground: true,
            backgroundColor: 'white',
            backgroundPadding: 0,
            space: 0,

            showZeroOuterStroke: false,
            outerStrokeWidth: 5,
            outerStrokeColor,

            innerStrokeWidth: 3,
            innerStrokeColor,

            animateTitle: false,
            animation: false,
            renderOnClick: false,
        } as CircleProgressOptions;
    }
}
