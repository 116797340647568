<ion-content class="how-to-weigh-your-dog-modal" [color]="Color.Luna">
    <button
        class="xl how-to-weigh-your-dog-modal__close-button"
        appTrackClick
        identifier="nourish-how-to-weigh-your-dog-modal-close-btn"
        (click)="onCloseClick()"
    >
        <i class="fa-light fa-xmark"></i>
    </button>

    <article class="how-to-weigh-your-dog-modal__article page-padding-sides page-padding-bottom ignore-global-color">
        <h2>How to weigh your dog?</h2>
        <p>
            Keeping track of your dog’s weight is essential for their health, but getting it right can sometimes be
            tricky.
        </p>

        @if (ageInWeeks() < 52) {
            <section class="how-to-weigh-your-dog-modal__highlighted-text-paragraph">
                <p>You should weigh {{ dogName() }} every 2 weeks to monitor steady growth</p>
            </section>
        } @else {
            <section class="how-to-weigh-your-dog-modal__highlighted-text-paragraph">
                <p>You should weigh {{ dogName() }} about every month to monitor healthy weigh</p>
            </section>
        }

        <h3 class="how-to-weigh-your-dog-modal__subheading">Using bathroom scale</h3>
        <ul class="how-to-weigh-your-dog-modal__list">
            <li><p>Step on the scale holding your dog and note the combined weight</p></li>
            <li><p>Then weigh yourself alone</p></li>
            <li><p>Subtract your weight from the combined weight to get your dog’s weight</p></li>
        </ul>

        <section class="how-to-weigh-your-dog-modal__highlighted-text">
            <p>
                (<i class="fa-solid fa-dog"></i> + <i class="fa-solid fa-person"></i>) -
                <i class="fa-solid fa-person"></i> = Your pup’s weight
            </p>
        </section>

        <h3 class="how-to-weigh-your-dog-modal__subheading">Using pet scale (if available)</h3>
        <ul class="how-to-weigh-your-dog-modal__list">
            <li><p>Use a platform scale or floor scale if available</p></li>
            <li><p>For restless dogs, weigh with treats or a calm command to keep them still</p></li>
        </ul>

        <h3 class="how-to-weigh-your-dog-modal__subheading">Calculate food portions</h3>
        <p>Frequent checks help you adjust food portions as your puppy grows to prevent overfeeding or underfeeding.</p>
        <p>Use our food calculator to calculate how much you should be feeding Hazelnut.</p>

        <div class="how-to-weigh-your-dog-modal__calculator-link">
            <ion-button
                appTrackClick
                class="how-to-weigh-your-dog-modal__calculate-button s"
                identifier="nourish-calculate-button"
                color="max"
                expand="block"
                data-test="article-read"
                (click)="goToFoodCalculator()"
            >
                <i class="fa-solid fa-plus"></i>Calculate food portion
            </ion-button>
        </div>

        <br />

        <section class="how-to-weigh-your-dog-modal__highlighted-text-paragraph">
            <h3>When to see the vet?</h3>
            <p>Sudden weight changes (gain or loss) may indicate health issues.</p>
        </section>
    </article>
</ion-content>
