import { Component, inject, input } from '@angular/core';
import { householdFeature, selectBreedName } from '@frontend/data-access/user/household';
import { AgePipe, SliderComponent, SliderItemComponent } from '@frontend/ui';
import { Store } from '@ngrx/store';
import { NourishProductId } from '../constants/nourish.constants';
import { NourishProductTile } from '../models/nourish.model';
import { NourishProductTileComponent } from '../nourish-product-tile/nourish-product-tile.component';
import { productTileClicked } from './store/nourish-product-tiles.actions';

@Component({
    selector: 'app-nourish-product-tiles',
    templateUrl: './nourish-product-tiles.component.html',
    standalone: true,
    imports: [AgePipe, NourishProductTileComponent, SliderComponent, SliderItemComponent],
})
export class NourishProductTilesComponent {
    private readonly store = inject(Store);

    public dateOfBirth = this.store.selectSignal(householdFeature.selectDateOfBirth);
    public breedName = this.store.selectSignal(selectBreedName);

    public productTiles = input<NourishProductTile[]>([]);
    public correlationId = input<'page' | 'modal' | undefined>();

    public onClickProductTile(id: NourishProductId) {
        this.store.dispatch(
            productTileClicked({
                id,
                correlationId: this.correlationId(),
            }),
        );
    }
}
