<ion-content
    class="page-safe-margin"
    [fullscreen]="true"
    [scrollEvents]="true"
    [scrollY]="canScroll()"
    (ionScroll)="setScrollHeight($any($event))"
>
    <div class="courses-page-course-header page-padding-sides page-padding-top">
        <h1 class="page-title">My Journey</h1>
    </div>

    @if (selectedCourse()) {
    <div class="courses-page-main">
        <app-course-slider data-test="course-swipe" [courses]="courses()" />
        <div class="courses-page-main__no-access-section">
            @if (displayNoAccess()) {
            <app-no-access
                title="Unlock additional courses with premium"
                subTitle="You're on the Basic plan now."
                buttonText="Upgrade to Premium"
                (openPaymentModal)="openPaymentModal()"
            />
            }

            <div class="courses-page-main__course-path page-padding-sides">
                <app-course-path />
            </div>

            @if (!isStartCourseAb() && selectedCourse()!.status !== CourseStatus.COMPLETED) {
            <div
                *appElementRerender="selectedCourse()!.color"
                class="courses-page-main__scroll-down-container"
                data-test="scroll-down-button"
                [ngClass]="{'courses-page-main__scroll-down-container--point-up': (heightOfFirstInactiveStep() ?? 0) < scrollHeight}"
                (click)="scrollToFirstInactiveStep()"
            >
                <i
                    class="fa-solid fa-circle-chevron-down fa-2xl"
                    [style.color]="'var(--ion-color-' + selectedCourse()!.color + ')'"
                ></i>
            </div>
            }

            <div class="page-padding-sides">
                @if (nextCourse()) {
                <app-generic-card-large
                    callToActionText="View course"
                    label="Next Up"
                    data-test="next-up-tile"
                    [title]="nextCourse()!.title"
                    [description]="nextCourse()!.introduction"
                    [imageUrl]="nextCourse()!.imageUrl"
                    [imageAltText]="nextCourse()!.subTitle || 'An image of a dog excited about the next course'"
                    [backgroundColor]="nextCourse()!.color"
                    [backgroundUrl]="getCourseCardDoodle()"
                    (callToActionClicked)="openCourseInfoModal()"
                />
                }
            </div>

            @if (!displayNoAccess()) {
            <div class="courses-page-main__paw-container page-padding-sides">
                @if (showStartCourseButton()) {
                <div class="courses-page-main__start-container">
                    <button
                        data-test="course-page-start-course-btn"
                        [class]="'courses-page-main__start-course-button ' + selectedCourse()!.color"
                        (click)="setCourseAsInProgress()"
                    >
                        Start course
                    </button>
                </div>
                } @else if (isStartCourseAb() && selectedCourse()!.status === CourseStatus.IN_PROGRESS) { @if
                (this.isStreakCardOpen) {
                <section [class]="'courses-page-streak-card ' + selectedCourse()!.color" (click)="expandStreakCard()">
                    <section class="courses-page-streak-card__container">
                        <div>
                            <h3 class="courses-page-streak-card__title">Day Streak</h3>
                            <label class="courses-page-streak-card__subtitle"
                                >Complete 3 lessons a day to earn a streak</label
                            >
                        </div>
                        <div class="courses-page-streak-card__paw-container">
                            <i class="courses-page-streak-card__icon fa-solid fa-paw fa-2xl"></i>
                            <h3 class="courses-page-streak-card__text">{{ courseStreak()?.length ?? 0 }}</h3>
                        </div>
                    </section>
                    <app-progress-streak [shouldShowDate]="true" />
                </section>
                } @else {
                <button
                    data-test="courses-page-streak-btn"
                    [class]="'courses-page-streak-button as-div ' + selectedCourse()!.color"
                    (click)="expandStreakCard()"
                >
                    <i class="courses-page-streak-button__icon fa-solid fa-paw fa-2xl"></i>
                    <h3 class="courses-page-streak-button__text">{{ courseStreak()?.length ?? 0 }}</h3>
                </button>
                <button
                    data-test="courses-page-today-lessons-btn"
                    [class]="'courses-page-today-lessons-button as-div ' + selectedCourse()?.color"
                    (click)="scrollToFirstInactiveStep()"
                >
                    <span>Today's lessons</span>
                    <span
                        class="courses-page-today-lessons-button__steps-completed"
                        [ngStyle]="{
                                    'color': 'var(--ion-color-' + selectedCourse()?.color
                                }"
                    >
                        @if (selectedCourse()?.numberOfUniqueStepsCompletedToday < 3) { {{
                        selectedCourse()?.numberOfUniqueStepsCompletedToday }}/3 } @else {
                        <div>
                            <i class="fa-regular fa-check"></i>
                        </div>
                        }
                    </span>
                </button>
                } }
            </div>
            }
        </div>
    </div>
    }
</ion-content>
