import { createAction, props } from '@ngrx/store';
import { NourishProductId } from '../../constants/nourish.constants';

export const productTileClicked = createAction(
    '[Nourish] Product Tile Clicked',
    props<{
        id: NourishProductId;
        correlationId?: 'page' | 'modal';
    }>(),
);
