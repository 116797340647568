import { Component, inject, viewChild } from '@angular/core';
import { chatFeature } from '@frontend/data-access/chat';
import { ChatTileTopic } from '@frontend/data-access/contentful';
import {
    ChatTileContentOption,
    GenericCardSquareComponent,
    OptionSelectorSquareComponent,
    SliderComponent,
    SliderItemComponent,
} from '@frontend/ui';
import { IonContent, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { routeToCoachChat } from '../../../chat/chat.actions';
import { showPaymentModal } from '../../../store/payment/store/payment.actions';
import { selectIsBasicPlan } from '../../../store/payment/store/payment.selectors';
import { suggestedAiChatQuestionClicked } from '../../../today/data-access/today.page.actions';
import { TodayGenericCardSquare } from '../../../today/today.model';
import {
    DEFAULT_NOURISH_PRODUCT_TILES,
    NOURISH_INSIGHTS_TILES,
    NourishTileId,
} from '../../constants/nourish.constants';
import { openNourishModal } from '../../modals/store/nourish-modal.actions';
import { NourishHeaderComponent } from '../../nourish-header/nourish-header.component';
import { NourishProductTilesComponent } from '../../nourish-product-tiles/nourish-product-tiles.component';
import { NourishTileComponent } from '../../nourish-tile/nourish-tile.component';
import { selectNourishPageSupportTiles } from './nourish.page.selectors';

@Component({
    selector: 'app-nourish',
    templateUrl: './nourish.page.html',
    styleUrl: './nourish.page.scss',
    standalone: true,
    imports: [
        IonContent,
        NourishHeaderComponent,
        NourishProductTilesComponent,
        NourishTileComponent,
        SliderComponent,
        SliderItemComponent,
        GenericCardSquareComponent,
        OptionSelectorSquareComponent,
        IonHeader,
        IonToolbar,
    ],
})
export class NourishPage {
    private readonly store = inject(Store);

    // NBSon - right now we assume there will always be a single chat tile, if this changes, we should use ViewChildren and iterate over them
    public chatTileRef = viewChild<OptionSelectorSquareComponent>('chatTile');

    public supportTiles = this.store.selectSignal(selectNourishPageSupportTiles);
    public isStreamChatBusyCleaningUp = this.store.selectSignal<boolean>(chatFeature.selectIsDestroying);
    public isBasicPlan = this.store.selectSignal(selectIsBasicPlan);

    protected readonly Color = Color;

    public insightsTiles = NOURISH_INSIGHTS_TILES;
    public productTiles = DEFAULT_NOURISH_PRODUCT_TILES;

    public onCardClick(unlocked: boolean, cardName: string | NourishTileId): void {
        if (this.isStreamChatBusyCleaningUp()) {
            return;
        }

        if (!unlocked) {
            this.store.dispatch(
                showPaymentModal({
                    trigger: cardName,
                }),
            );

            return;
        }

        switch (cardName) {
            case 'puppy-coach': {
                this.store.dispatch(routeToCoachChat());
                break;
            }
            case NourishTileId.NUTRITIONAL_CONSULTATION: {
                this.store.dispatch(
                    openNourishModal({
                        id: cardName,
                    }),
                );
                break;
            }
            default: {
                break;
            }
        }
    }

    public onChatTileTopicClick(option: ChatTileContentOption | undefined): void {
        if (!this.chatTileRef() || !option) {
            return;
        }

        this.store.dispatch(
            suggestedAiChatQuestionClicked({
                optionId: option.id,
                option: option.shortQuestion,
                card: option.longQuestion,
            }),
        );

        this.chatTileRef()!.clearSelection();
    }

    public isTodayGenericCardSquare(
        content: TodayGenericCardSquare | ChatTileTopic,
    ): content is TodayGenericCardSquare {
        return 'cardName' in content && 'contentUnlocked' in content;
    }

    public isChatTileTopic(content: TodayGenericCardSquare | ChatTileTopic): content is ChatTileTopic {
        return 'chatTileContent' in content;
    }
}
