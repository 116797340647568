import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { IonContent } from '@ionic/angular/standalone';
import { DEFAULT_NOURISH_PRODUCT_TILES } from '../../constants/nourish.constants';
import { NourishProductTilesComponent } from '../../nourish-product-tiles/nourish-product-tiles.component';
import { NourishModalBaseComponent } from '../nourish-modal-base.component';

@Component({
    standalone: true,
    selector: 'app-making-the-right-food-choice-modal',
    templateUrl: './making-the-right-food-choice-modal.component.html',
    styleUrl: './making-the-right-food-choice-modal.component.scss',
    imports: [NgClass, IonContent, NourishProductTilesComponent, AnalyticsTrackClickDirective],
})
export class MakingTheRightFoodChoiceModalComponent extends NourishModalBaseComponent {
    protected readonly productTiles = DEFAULT_NOURISH_PRODUCT_TILES;
}
