export enum SaGenderAnswer {
    MALE = 'male',
    FEMALE = 'female',
}

export enum SaIsNeuteredAnswer {
    YES = 'yes',
    NO = 'no',
    UNKNOWN = 'unknown',
}

export enum SaAgeAnswer {
    THREE_TO_SIX_MONTHS = '3 to 6 months',
    SIX_TO_TWELVE_MONTHS = '6 to 12 months',
    ONE_TO_TWO_YEARS = '1 to 2 years',
    OVER_TWO_YEARS = 'over two years',
}

export enum SaAgeOfOnsetAnswer {
    ALWAYS_LESS_THAN_SIX_MONTHS = 'always present - less than 6 months old',
    ALWAYS_OVER_SIX_MONTHS = 'always present - more than 6 months old',
    SIX_MONTHS_TO_TWO_YEARS = '6 months to 2 years',
    TWO_TO_SEVEN_YEARS = '2 to 7 years',
    OVER_SEVEN_YEARS = 'more than 7 years',
    UNKNOWN = 'unknown',
}

export enum SaKnownTraumaticEventAnswer {
    YES = 'yes',
    NO = 'no',
    UNKNOWN = 'unknown',
}

export enum SaDurationAnswer {
    ZERO_TO_ONE_YEAR = '0-1 year',
    ONE_TO_TWO_YEAR = '1-2 years',
    THREE_TO_FOUR_YEAR = '3-4 years',
    FIVE_PLUS_YEARS = '5+ years',
    UNKNOWN = 'unknown',
}

export const saAgeOfOnsetDbValue = {
    [SaAgeOfOnsetAnswer.ALWAYS_LESS_THAN_SIX_MONTHS]: 1,
    [SaAgeOfOnsetAnswer.ALWAYS_OVER_SIX_MONTHS]: 2,
    [SaAgeOfOnsetAnswer.SIX_MONTHS_TO_TWO_YEARS]: 3,
    [SaAgeOfOnsetAnswer.TWO_TO_SEVEN_YEARS]: 4,
    [SaAgeOfOnsetAnswer.OVER_SEVEN_YEARS]: 5,
    [SaAgeOfOnsetAnswer.UNKNOWN]: 6,
};

export enum SaProblemType {
    EXIT_FRUSTRATION = 'Exit frustration',
    BOREDOM = 'Boredom',
    REDIRECT_REACTIVE = 'Redirected reactive',
    REACTIVE_INHIBITED = 'Reactive inhibited',
}

export const saProblemTypeLookup: Record<number, SaProblemType> = {
    1: SaProblemType.EXIT_FRUSTRATION,
    2: SaProblemType.BOREDOM,
    3: SaProblemType.REDIRECT_REACTIVE,
    4: SaProblemType.REACTIVE_INHIBITED,
};
