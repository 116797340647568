import { Component } from '@angular/core';
import { IonContent } from '@ionic/angular/standalone';
import { AuthorChipComponent } from '@frontend/ui';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { NourishModalBaseComponent } from '../nourish-modal-base.component';

@Component({
    standalone: true,
    selector: 'app-pup-not-eating-modal',
    templateUrl: './pup-not-eating-modal.component.html',
    styleUrl: './pup-not-eating-modal.component.scss',
    imports: [IonContent, AuthorChipComponent, AnalyticsTrackClickDirective],
})
export class PupNotEatingModalComponent extends NourishModalBaseComponent {}
