import { Component } from '@angular/core';
import { IonContent } from '@ionic/angular/standalone';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { NourishModalBaseComponent } from '../nourish-modal-base.component';

@Component({
    standalone: true,
    selector: 'app-what-poop-can-tell-you-modal',
    templateUrl: './what-can-poop-tell-you-modal.component.html',
    styleUrl: './what-can-poop-tell-you-modal.component.scss',
    imports: [IonContent, AnalyticsTrackClickDirective],
})
export class WhatCanPoopTellYouModalComponent extends NourishModalBaseComponent {}
