import { Component, computed, inject, input, model, OnInit } from '@angular/core';
import { purchaseProduct, restoreSubscription, subscriptionFeature } from '@frontend/data-access/subscription';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { selectIsTieredPricingPaywallAb } from '@frontend/data-access/user/config-cat';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderComponent } from '@frontend/feature/header';
import { ModalService } from '@frontend/utility/modal';
import { IonButton, IonChip, IonContent, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { closeModal } from '../../store/modal/modal.actions';
import { PaymentModalProductComponent } from '../payment-modal-product/payment-modal-product.component';
import { PaymentProductCard } from '../payment-modal-product/payment-product-card.model';
import { PaymentModalTermsComponent } from '../payment-modal-terms/payment-modal-terms.component';
import { TieredPricingComparisonModalComponent } from '../payment-modal-tiered-pricing/tiered-pricing-comparison-modal/tiered-pricing-comparison-modal.component';
import { TieredPricingComparisonToggleComponent } from '../payment-modal-tiered-pricing/tiered-pricing-comparison-toggle/tiered-pricing-comparison-toggle.component';
import { PAYMENT_MODAL_PLAN_COMPARISON_ID } from '../payment-modal.constant';
import { PaymentModalLimitedOfferProductComponent } from './payment-modal-limited-offer-product/payment-modal-limited-offer-product.component';

@Component({
    selector: 'app-payment-modal-limited-offer',
    templateUrl: './payment-modal-limited-offer.component.html',
    styleUrls: ['./payment-modal-limited-offer.component.scss'],
    standalone: true,
    imports: [
        IonContent,
        IonToolbar,
        IonHeader,
        HeaderComponent,
        TextReplacementPipe,
        AnalyticsTrackClickDirective,
        IonButton,
        IonFooter,
        PaymentModalLimitedOfferProductComponent,
        PaymentModalProductComponent,
        TieredPricingComparisonToggleComponent,
        IonChip,
        PaymentModalTermsComponent,
    ],
})
export class PaymentModalLimitedOfferComponent implements OnInit {
    private readonly store = inject(Store);
    private readonly modalService = inject(ModalService);
    protected readonly Color = Color;

    // products shown in this modal (only 1 when it pops up after onboarding)
    public shownProducts = input<PaymentProductCard[]>([]);

    // products to be passed on to comparison modal
    public limitedOfferProducts = input<PaymentProductCard[]>([]);
    public basicProducts = input<PaymentProductCard[]>([]);

    public selectedProduct = model<PaymentProductCard>();

    public isTieredPricingPaywallAb = this.store.selectSignal(selectIsTieredPricingPaywallAb);
    public isPurchaseInProgress = this.store.selectSignal(subscriptionFeature.selectIsPurchaseInProgress);

    public discount = computed(() => {
        const discountedProduct = this.shownProducts().find((product) => !!product.discount);

        if (!discountedProduct) {
            return undefined;
        }

        return discountedProduct.discount?.discountPercentage;
    });

    ngOnInit() {
        this.selectedProduct.set(this.shownProducts()[0]);
    }

    public onCloseModalClick(): void {
        this.store.dispatch(closeModal());
    }

    public onProductClick(product: PaymentProductCard): void {
        this.selectedProduct.set(product);
    }

    public async launchComparisonModal() {
        const selectedValues = await this.modalService.showModalWithCallbackAsync<{
            isPremiumSelected: boolean;
            selectedProduct: PaymentProductCard;
        }>({
            id: PAYMENT_MODAL_PLAN_COMPARISON_ID,
            component: TieredPricingComparisonModalComponent,
            componentProps: {
                premiumProducts: this.limitedOfferProducts(),
                basicProducts: this.basicProducts(),
                initialIsPremiumSelected: true,
                initialSelectedProduct: this.selectedProduct() || this.shownProducts()[0],
            },
            cssClass: ['modal', 'modal-large'],
        });

        // Only select the product if we show that product on this page
        const foundProduct = this.shownProducts().find(
            (product) => product.identifier === selectedValues?.selectedProduct.identifier,
        );

        if (foundProduct) {
            this.selectedProduct.set(foundProduct);
        }
    }

    public onRestoreClick(): void {
        this.store.dispatch(restoreSubscription());
    }

    public onStartTrialClick(): void {
        const selectedProduct = this.selectedProduct();

        if (!selectedProduct) {
            return;
        }

        this.store.dispatch(
            purchaseProduct({
                data: {
                    identifier: selectedProduct.identifier,
                    priceWithUnit: selectedProduct.priceWithUnit,
                },
            }),
        );
    }
}
