import { inject, Injectable } from '@angular/core';
import { ModalService } from '@frontend/utility/modal';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, tap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NourishTileId } from '../../constants/nourish.constants';
import { MakingTheRightFoodChoiceModalComponent } from '../making-the-right-food-choice/making-the-right-food-choice-modal.component';
import { PreventDiarrhoeaModalComponent } from '../prevent-diarrhoea-modal/prevent-diarrhoea-modal.component';
import { WhatCanPoopTellYouModalComponent } from '../what-can-poop-tell-you-modal/what-can-poop-tell-you-modal.component';
import { WhenAndHowToSwitchFoodModalComponent } from '../when-and-how-to-switch-food-modal/when-and-how-to-switch-food-modal.component';
import { HowMuchExerciseModalComponent } from '../how-much-exercise-is-enough/how-much-exercise-is-enough-modal.component';
import { PupNotEatingModalComponent } from '../pup-not-eating-modal/pup-not-eating-modal.component';
import { HowToWeighYourDogModalComponent } from '../how-to-weigh-your-dog-modal/how-to-weigh-your-dog-modal.component';
import { HelpWithTummyTroublesModalComponent } from '../help-with-tummy-troubles/help-with-tummy-troubles-modal.component';
import { closeNourishModal, openNourishModal } from './nourish-modal.actions';
import { NutritionModalComponent } from '../nutrition-modal/nutrition-modal.component';

// TODO: NBSon - think about splitting up effects, handling tracking for open and close - check the template trackClickEvent for all modals

@Injectable()
export class NourishModalEffects {
    private actions$ = inject(Actions);
    private modalService = inject(ModalService);

    openNourishModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(openNourishModal),
                tap(({ id }) => {
                    let component;

                    switch (id) {
                        case NourishTileId.WHAT_CAN_POOP_TELL_YOU: {
                            component = WhatCanPoopTellYouModalComponent;
                            break;
                        }
                        case NourishTileId.PREVENT_DIARRHOEA: {
                            component = PreventDiarrhoeaModalComponent;
                            break;
                        }
                        case NourishTileId.RIGHT_FOOD_CHOICE: {
                            component = MakingTheRightFoodChoiceModalComponent;
                            break;
                        }
                        case NourishTileId.SWITCH_FOOD: {
                            component = WhenAndHowToSwitchFoodModalComponent;
                            break;
                        }
                        case NourishTileId.HOW_MUCH_EXERCISE: {
                            component = HowMuchExerciseModalComponent;
                            break;
                        }
                        case NourishTileId.PUP_NOT_EATING: {
                            component = PupNotEatingModalComponent;
                            break;
                        }
                        case NourishTileId.WEIGH_YOUR_DOG: {
                            component = HowToWeighYourDogModalComponent;
                            break;
                        }
                        case NourishTileId.NUTRITIONAL_CONSULTATION: {
                            component = NutritionModalComponent;
                            break;
                        }
                        case NourishTileId.TUMMY_TROUBLES: {
                            component = HelpWithTummyTroublesModalComponent;
                            break;
                        }
                        default: {
                            return EMPTY;
                        }
                    }

                    return this.modalService.showModal({
                        component: component,
                        cssClass: ['modal', 'modal-large'],
                    });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    closeNourishModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(closeNourishModal),
                tap(() => {
                    void this.modalService.dismiss();
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );
}
