import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { IonContent } from '@ionic/angular/standalone';
import {
    NOURISH_LILY_KITCHEN_PRODUCT_TILE,
    NOURISH_PURINA_PRO_PLAN_PRODUCT_TILE,
    NOURISH_TAILS_PRODUCT_TILE,
} from '../../constants/nourish.constants';
import { NourishProductTilesComponent } from '../../nourish-product-tiles/nourish-product-tiles.component';
import { NourishModalBaseComponent } from '../nourish-modal-base.component';

@Component({
    standalone: true,
    selector: 'app-when-and-how-to-switch-food-modal',
    templateUrl: './when-and-how-to-switch-food-modal.component.html',
    styleUrl: './when-and-how-to-switch-food-modal.component.scss',
    imports: [NgClass, IonContent, NourishProductTilesComponent, AnalyticsTrackClickDirective],
})
export class WhenAndHowToSwitchFoodModalComponent extends NourishModalBaseComponent {
    protected readonly productTiles = [
        NOURISH_PURINA_PRO_PLAN_PRODUCT_TILE,
        NOURISH_LILY_KITCHEN_PRODUCT_TILE,
        NOURISH_TAILS_PRODUCT_TILE,
    ];
}
