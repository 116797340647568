<ion-content class="what-poop-can-tell-you-modal" [color]="Color.Luna">
    <button
        class="xl what-poop-can-tell-you-modal__close-button"
        appTrackClick
        identifier="nourish-what-poop-can-tell-you-modal-close-btn"
        (click)="onCloseClick()"
    >
        <i class="fa-light fa-xmark"></i>
    </button>
    <article class="what-poop-can-tell-you-modal__article page-padding-sides page-padding-bottom ignore-global-color">
        <h2>What is your pup's poop telling you?</h2>

        <img
            class="what-poop-can-tell-you-modal__illustration"
            src="/assets/images/illustrations/nourish/dog-poop-inside-red-slipper.svg"
        />

        <p>Your pup’s poop can reveal a lot about their health! Here’s a quick guide to interpreting it:</p>

        <h3 class="what-poop-can-tell-you-modal__subheading no-margin-top">🟢 Healthy poop</h3>
        <ul class="what-poop-can-tell-you-modal__list no-margin-top">
            <li>
                <b>Appearance:</b>
                <p class="no-margin">Firm, log-shaped, chocolate-brown in colour.</p>
            </li>
            <li>
                <b>Consistency:</b>
                <p class="no-margin">Easy to pick up, not too hard or mushy.</p>
            </li>
            <li>
                <b>Frequency:</b>
                <p class="no-margin">1-3 times per day, depending on diet and activity.</p>
            </li>
        </ul>

        <section class="what-poop-can-tell-you-modal__highlighted-text-snowy no-margin">
            <img
                class="what-poop-can-tell-you-modal__small-illustration"
                src="/assets/images/buddy/buddy-sits-patiently.svg"
                alt="Buddy sits patiently"
            />
            <div>
                <h3 class="no-margin">Healthy poop = a healthy pup!</h3>
                <p class="no-margin">Keep up the good work.</p>
            </div>
        </section>

        <h3 class="what-poop-can-tell-you-modal__subheading">🔴 Warning signs to watch for</h3>
        <ul class="what-poop-can-tell-you-modal__list no-margin">
            <li>
                <b>Loose or Watery Stools (diarrhoea):</b>
                <p class="no-margin">May indicate dietary changes, stress, parasites, or infections.</p>
            </li>
            <li>
                <b>Hard or Dry Stools:</b>
                <p class="no-margin">Could mean dehydration or lack of fibre in the diet.</p>
                <p class="no-margin">Could mean dehydration or lack of fibre in the diet.</p>
            </li>

            <div>
                <li>
                    <b>Unusual colours:</b>
                </li>
                <ul class="what-poop-can-tell-you-modal__list-no-style">
                    <li>
                        <p class="no-margin">
                            <b>- Black/Tarry:</b> Possible internal bleeding—contact a vet immediately.
                        </p>
                    </li>
                    <li>
                        <p class="no-margin">
                            <b>- Red Streaks:</b> May indicate blood; monitor closely and consult your vet.
                        </p>
                    </li>
                    <li>
                        <p class="no-margin"><b>- Yellow or Grey:</b> Could point to liver or pancreas issues.</p>
                        <p class="no-margin"><b>- Mucus:</b> A sign of irritation or inflammation in the intestines.</p>
                        <p class="no-margin">
                            <b>- Foreign objects or worms:</b> Indicates ingestion of something indigestible or a
                            parasite infestation.
                        </p>
                    </li>
                </ul>
            </div>
        </ul>

        <h3 class="no-margin-bottom">🐶 What can you do?</h3>
        <ul class="what-poop-can-tell-you-modal__list-no-style no-margin">
            <li>
                <p>
                    <b>Monitor Regularly:</b> Check your pup’s stool daily for changes in color, shape, or consistency.
                </p>
            </li>
            <li>
                <p><b>Hydrate:</b> Ensure your pup has plenty of fresh water to support healthy digestion.</p>
            </li>
            <li>
                <p>
                    <b>Vet Check:</b> If abnormalities persist for more than 24-48 hours or are severe, consult your vet
                    immediately.
                </p>
            </li>
            <li>
                <p><b>Keep a poop diary:</b> Track changes to help your vet identify potential issues.</p>
            </li>
        </ul>

        <p class="what-poop-can-tell-you-modal__highlighted-text">
            💡A quick poop check can be your first clue to keeping your pup happy and healthy! 🐕
        </p>

        <img
            class="what-poop-can-tell-you-modal__poop-chart"
            src="/assets/images/nourish/poop-chart.png"
            alt="an infographic explaining whether or not your pup's poop is healthy"
        />
    </article>
</ion-content>
