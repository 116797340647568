export enum AdjustEventType {
    FIVE_LESSONS_COMPLETED = 'fiveLessonsCompleted',
    ONBOARDING_COMPLETED = 'onboardingCompleted',
    YOUNG_PUPPY_INSTALL = 'youngPuppyInstall',
}

export const adjustEventMap: Record<AdjustEventType, string> = {
    [AdjustEventType.FIVE_LESSONS_COMPLETED]: 'd3hqog',
    [AdjustEventType.ONBOARDING_COMPLETED]: 'sovf5n',
    [AdjustEventType.YOUNG_PUPPY_INSTALL]: 'w23x7i',
};

export interface AdjustEventPayload {
    profileId: string;
    event: AdjustEventType;
}
