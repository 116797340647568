import { ChatTileTopic, chatTileTopicsFeature } from '@frontend/data-access/contentful';
import { createSelector } from '@ngrx/store';
import { FEEDING_AND_CHEWING_CHAT_TILE_CONTENTFUL_IDS } from '@shared/constants';
import { Color } from '@shared/utils/typescript';
import { selectContentUnlocked, selectIsBasicPlan } from '../../../store/payment/store/payment.selectors';
import { TodayGenericCardSquare } from '../../../today/today.model';
import { NourishTileId } from '../../constants/nourish.constants';

export const selectNourishPageChatTile = createSelector(
    chatTileTopicsFeature.selectChatTileTopics,
    (chatTileTopics): ChatTileTopic | undefined => {
        return chatTileTopics.find((chatTileTopic) =>
            FEEDING_AND_CHEWING_CHAT_TILE_CONTENTFUL_IDS.includes(chatTileTopic.id),
        );
    },
);

export const selectNourishPageGenericCards = createSelector(
    selectContentUnlocked,
    selectIsBasicPlan,
    (contentUnlocked, isBasicPlan): TodayGenericCardSquare[] => {
        return [
            {
                imageUrl: '/assets/images/bailey/bailey-holds-phone-in-mouth.svg',
                title: 'FREE nutritional consultation',
                backgroundColor: Color.Lola,
                cardName: NourishTileId.NUTRITIONAL_CONSULTATION,
                contentUnlocked: !isBasicPlan,
                isBasicPlan,
            },
            {
                imageUrl: '/assets/images/trainers/coach-petrina-red-circle.png',
                title: 'Training coach',
                description: 'Replies within 1 hour',
                backgroundColor: Color.Harley,
                cardName: 'puppy-coach',
                contentUnlocked: contentUnlocked && !isBasicPlan,
                isBasicPlan,
            },
        ];
    },
);

export const selectNourishPageSupportTiles = createSelector(
    selectNourishPageGenericCards,
    selectNourishPageChatTile,
    (genericCards, chatTile): (TodayGenericCardSquare | ChatTileTopic)[] => {
        const supportTiles: (TodayGenericCardSquare | ChatTileTopic)[] = [...genericCards];

        // Insert the chat tiles at index 1
        if (chatTile) {
            supportTiles.splice(1, 0, chatTile);
        }

        return supportTiles;
    },
);
