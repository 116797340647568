import { DomainUserWebDto } from '../../../dtos/domain-api/user/user/user-web/domain-user-web.dto';

export class DomainUserWebDtoMock {
    private defaultValue: DomainUserWebDto = {
        id: 'uuid-1234',
        userId: 'userid-1234',
        userIdGa4: 'useridga4-1234',
    };

    constructor(overrides?: Partial<DomainUserWebDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): DomainUserWebDto {
        return this.defaultValue;
    }
}
