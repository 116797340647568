import { AppRatingEffects } from '@frontend/data-access/app-rating';
import { DismissibleInfoEffects } from '@frontend/data-access/dismissible-info';
import { HapticFeedbackEffects } from '@frontend/utility/native-plugins';
import { AiChatEffects } from '../chat/ai-chat/store/ai-chat.effects';
import { CoachChatEffects } from '../chat/ai-chat/store/coach-chat.effects';
import { ChatEffects } from '../chat/chat.effects';
import { CourseCompletionModalEffects } from '../courses/course-completion-modal/course-completion-modal.effects';
import { CourseInformationModalEffects } from '../courses/course-information-modal/course-information-modal.effects';
import { JumpAheadModalEffects } from '../courses/course-path/jump-ahead-modal/jump-ahead-modal.effects';
import { CoursePathEffects } from '../courses/course-path/store/course-path.effects';
import { CourseSliderEffects } from '../courses/course-slider/course-slider.effects';
import { DailyBiteModalEffects } from '../daily-bites/daily-bite-modal/daily-bite-modal.effects';
import { FoodCalculatorEffects } from '../food-calculator/data-access/store/food-calculator.effects';
import { OnboardingEffects } from '../onboarding/data-access/onboarding.effects';
import { PupHappinessModalEffects } from '../pup-happiness-modal/store/pup-happiness-modal.effects';
import { RatingQualifierModalEffects } from '../rating/app-rating-qualifier/store/rating-qualifier-modal.effects';
import { TodayPageEffects } from '../today/data-access/today.page.effects';
import { TrainingReminderModalEffects } from '../training-reminder/store/training-reminder-modal.effects';
import { ActionSheetEffects } from './action-sheet/action-sheet.effects';
import { AnalyticsEffects } from './analytics/analytics.effects';
import { AppComponentEffects } from './app-component/app-component.effects';
import { ConnectingAnalyticsEffects } from './connecting-analytics/connecting-analytics.effects';
import { ContentEffects } from './content/content.effects';
import { DeeplinkEffects } from './deeplink/deeplink.effects';
import { ErrorEffects } from './error.effects';
import { InfoDialogEffects } from './info-dialog/info-dialog.effects';
import { ModalEffects } from './modal/modal.effects';
import { PaymentEffects } from './payment/store/payment.effects';
import { CreateAccountEffects } from './profile/account/create-account.effects';
import { DeleteAccountEffects } from './profile/account/delete-account.effects';
import { GetAccountEffects } from './profile/account/get-account.effects';
import { UpdateAccountEffects } from './profile/account/update-account.effects';
import { ProfileEffects } from './profile/profile.effects';
import { CourseProgressEffects } from './progress/course-progress/course-progress.effects';
import { StepProgressAnalyticsEffects } from './progress/step-progress/step-progress-analytics.effects';
import { StepProgressEffects } from './progress/step-progress/step-progress.effects';
import { PushNotificationsEffects } from './push-notifications/push-notifications.effects';
import { RouterEffects } from './router/router.effects';
import { ToastEffects } from './toast/toast.effects';
import { TopicEffects } from './topic/topic.effects';
import { AppHouseholdEffects } from './household/app-household.effects';
import { ProfileEditEffects } from '../profile/pages/profile-edit/store/profile-edit.effects';
import { DailyBiteModalAnalyticsEffects } from '../daily-bites/daily-bite-modal/daily-bite-modal-analytics.effects';
import { ProgressStreakModalEffects } from '../today/progress-streak/data-access/progress-streak-modal.effects';
import { NourishModalEffects } from '../nourish/modals/store/nourish-modal.effects';
import { NourishEffects } from '../nourish/store/nourish.effects';
import { NourishProductTilesEffects } from '../nourish/nourish-product-tiles/store/nourish-product-tiles.effects';

export const effects = [
    ActionSheetEffects,
    AiChatEffects,
    AnalyticsEffects,
    AppComponentEffects,
    AppRatingEffects,
    ChatEffects,
    CoachChatEffects,
    ConnectingAnalyticsEffects,
    ContentEffects,
    CourseCompletionModalEffects,
    CourseInformationModalEffects,
    CoursePathEffects,
    CourseProgressEffects,
    CourseSliderEffects,
    CreateAccountEffects,
    DailyBiteModalEffects,
    DailyBiteModalAnalyticsEffects,
    DeeplinkEffects,
    DeleteAccountEffects,
    DismissibleInfoEffects,
    ErrorEffects,
    FoodCalculatorEffects,
    GetAccountEffects,
    HapticFeedbackEffects,
    InfoDialogEffects,
    JumpAheadModalEffects,
    ModalEffects,
    OnboardingEffects,
    PaymentEffects,
    ProfileEffects,
    ProgressStreakModalEffects,
    PupHappinessModalEffects,
    PushNotificationsEffects,
    RatingQualifierModalEffects,
    RouterEffects,
    StepProgressAnalyticsEffects,
    StepProgressEffects,
    ToastEffects,
    TodayPageEffects,
    TopicEffects,
    TrainingReminderModalEffects,
    UpdateAccountEffects,
    AppHouseholdEffects,
    ProfileEditEffects,
    NourishModalEffects,
    NourishProductTilesEffects,
    NourishEffects,
];
