<ion-toolbar class="progress-header page-padding-sides">
    <ion-buttons slot="start">
        @if (showBackButton()) {
            <ion-button
                class="progress-header__button l"
                data-test="progress-header-back-button"
                [disabled]="isDisabled()"
                (click)="onClickBackButton()"
            >
                <app-icon iconName="arrow-left" sprite="regular" [color]="iconColour()" />
            </ion-button>
        }
    </ion-buttons>

    @if (showProgress()) {
        <div
            class="progress-header__progress-bar"
            [class.progress-header__progress-bar--has-back-button]="showBackButton()"
        >
            <ion-progress-bar
                data-test="progress-header-progress-bar"
                [value]="progressRatio()"
                [color]="Color.Sophie"
            />
        </div>
    }
</ion-toolbar>
