import { Routes } from '@angular/router';
import { FoodCalculatorOldPage } from './food-calculator-old/food-calculator-old.page';

const foodCalculatorOldRoutes: Routes = [
    {
        path: '',
        component: FoodCalculatorOldPage,
        pathMatch: 'full',
    },
    {
        path: 'chat',
        loadChildren: () => import('../../../chat/chat.routes'),
    },
];

export default foodCalculatorOldRoutes;
