<ion-card
    class="topic-tile tile"
    [ngStyle]="background()"
    [color]="Color.Harley"
    [attr.data-test]="'topic-tile-' + seasonalTopic().topic"
>
    <div class="topic-tile__inner">
        <div class="topic-tile__image-container">
            <img class="topic-tile__image" [src]="seasonalTopic().image" [alt]="seasonalTopic().title" />
        </div>
        <h3 class="topic-tile__title">{{ seasonalTopic().title }}</h3>
        <ion-button
            class="topic-tile__go-to-btn s"
            [ngStyle]="{
                '--color': seasonalTopic().backgroundColour + '-contrast',
                '--border-color': seasonalTopic().backgroundColour + '-contrast',
            }"
            appTrackClick
            identifier="topic-tile-go-to-btn"
            size="small"
            fill="outline"
            data-test="topic-tile-go-to-btn"
            (click)="onGoToTopicClick()"
        >
            View Topic
        </ion-button>
    </div>
</ion-card>
