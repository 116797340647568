<ion-item
    class="product-item"
    lines="full"
    button
    detail="false"
    appFoodProductSelector
    data-test="search-product-bar"
    [ngClass]="{ 'nourish-form': nourishForm() }"
    [products]="foodProducts$ | async"
    (foodProductChange)="selectFoodProduct($event)"
>
    <ion-label position="stacked">Product</ion-label>
    @if (selectedFoodProduct) {
        <h3 class="food-product" data-test="food-product">
            {{ selectedFoodProduct.brandName }} {{ selectedFoodProduct.name }}
        </h3>
    } @else {
        <h3 class="placeholder">Search brand & product</h3>
    }
    <i class="fa-solid fa-chevron-right icon" slot="end" size="small"></i>
</ion-item>
