import { selectIsStartCourseAb } from '@frontend/data-access/user/config-cat';
import { createSelector } from '@ngrx/store';
import { CourseStatus } from '../../store/course/course.model';
import { selectContentUnlocked } from '../../store/payment/store/payment.selectors';
import {
    selectSelectedCourseWithProgressInfo,
    selectSelectedCourseWithStepsAndSplits,
} from '../course-path/store/course-path.selectors';
import { selectCoursesWithProgressInfo } from '../../store/course/course-with-progress.selectors';
import { CourseWithProgress } from '../models/courses.models';

export const selectShouldShowStartCourseButtonForSelectedCourse = createSelector(
    selectContentUnlocked,
    selectSelectedCourseWithStepsAndSplits,
    selectIsStartCourseAb,
    (contentUnlocked, selectedCourse, isStartCourseAb) => {
        return (
            isStartCourseAb &&
            contentUnlocked &&
            !!selectedCourse &&
            selectedCourse.status !== CourseStatus.COMPLETED &&
            selectedCourse.status !== CourseStatus.IN_PROGRESS
        );
    },
);

export const selectShouldShowProgressForSelectedCourse = createSelector(
    selectContentUnlocked,
    selectSelectedCourseWithStepsAndSplits,
    selectIsStartCourseAb,
    (contentUnlocked, selectedCourse, isStartCourseAb) => {
        return (
            !isStartCourseAb ||
            (contentUnlocked && !!selectedCourse && selectedCourse.status !== CourseStatus.NOT_COMPLETED)
        );
    },
);

export const selectNextCourse = createSelector(
    selectCoursesWithProgressInfo,
    selectSelectedCourseWithProgressInfo,
    (courses, selectedCourse): CourseWithProgress | undefined => {
        return courses[courses.findIndex((course) => course.id === selectedCourse?.id) + 1] ?? undefined;
    },
);

export const selectCourses = createSelector(
    selectCoursesWithProgressInfo,
    selectContentUnlocked,
    selectIsStartCourseAb,
    (courses, contentUnlocked, isStartCourseAb) => {
        return courses.map((course) => ({
            ...course,
            showProgress: !isStartCourseAb || (contentUnlocked && course.status !== CourseStatus.NOT_COMPLETED),
        }));
    },
);
