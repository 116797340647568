import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { selectBreedName } from '@frontend/data-access/user/household';
import { IonButton, IonContent, IonFooter } from '@ionic/angular/standalone';
import { NourishModalBaseComponent } from '../nourish-modal-base.component';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { selectContentUnlocked } from '../../../store/payment/store/payment.selectors';
import { showPaymentModal } from '../../../store/payment/store/payment.actions';

@Component({
    standalone: true,
    selector: 'app-nutrition-modal',
    templateUrl: './nutrition-modal.component.html',
    styleUrl: './nutrition-modal.component.scss',
    imports: [NgClass, IonContent, AnalyticsTrackClickDirective, TextReplacementPipe, IonButton, IonFooter],
})
export class NutritionModalComponent extends NourishModalBaseComponent {
    public unlocked = this.store.selectSignal(selectContentUnlocked);
    public breedName = this.store.selectSignal(selectBreedName);

    public bookConsultation(): void {
        window.location.href = 'https://d5ufz7yvzse.typeform.com/to/Y25NZMCg';
    }

    public openPaymentModal(): void {
        this.store.dispatch(showPaymentModal({ trigger: 'nutrition-modal' }));
    }
}
