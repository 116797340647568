import { OnboardingRoutes } from '../onboarding-routing.model';

// Non-nourish constants
export const ONBOARDING_ROUTES_PROGRESS: Record<string, number> = {
    [OnboardingRoutes.NAME]: 2,
    [OnboardingRoutes.RESCUE]: 3,
    [OnboardingRoutes.AGE]: 4,
    [OnboardingRoutes.HAS_ARRIVED]: 5,
    [OnboardingRoutes.FIRST_DAY_AT_HOME]: 6,
    [OnboardingRoutes.ARRIVAL]: 6,
    [OnboardingRoutes.GENDER]: 7,
    [OnboardingRoutes.BREED]: 8,
    [OnboardingRoutes.OWNER_NAME]: 9,
    [OnboardingRoutes.NEEDS]: 10,
    [OnboardingRoutes.CARE_NEEDS]: 11,
    [OnboardingRoutes.NOTIFICATIONS]: 12,
    [OnboardingRoutes.MARKETING_OPT_IN]: 13,
};

export const ONBOARDING_ROUTES_CHECKPOINTS: Record<string, boolean> = {
    [OnboardingRoutes.NAME]: true,
    [OnboardingRoutes.RESCUE]: true,
    [OnboardingRoutes.AGE]: true,
    [OnboardingRoutes.HAS_ARRIVED]: true,
    [OnboardingRoutes.FIRST_DAY_AT_HOME]: false,
    [OnboardingRoutes.ARRIVAL]: false,
    [OnboardingRoutes.GENDER]: true,
    [OnboardingRoutes.BREED]: true,
    [OnboardingRoutes.OWNER_NAME]: true,
    [OnboardingRoutes.NEEDS]: true,
    [OnboardingRoutes.CARE_NEEDS]: true,
    [OnboardingRoutes.NOTIFICATIONS]: true,
    [OnboardingRoutes.MARKETING_OPT_IN]: false,
};

export const ONBOARDING_ROUTES_PROGRESS_US: Record<string, number> = {
    [OnboardingRoutes.NAME]: 2,
    [OnboardingRoutes.RESCUE]: 3,
    [OnboardingRoutes.AGE]: 4,
    [OnboardingRoutes.HAS_ARRIVED]: 5,
    [OnboardingRoutes.FIRST_DAY_AT_HOME]: 6,
    [OnboardingRoutes.ARRIVAL]: 6,
    [OnboardingRoutes.GENDER]: 7,
    [OnboardingRoutes.BREED]: 8,
    [OnboardingRoutes.OWNER_NAME]: 9,
    [OnboardingRoutes.NEEDS]: 10,
    [OnboardingRoutes.CARE_NEEDS]: 11,
    [OnboardingRoutes.NOTIFICATIONS]: 12,
    [OnboardingRoutes.PURINA_OPT_IN]: 13,
};

export const ONBOARDING_ROUTES_CHECKPOINTS_US: Record<string, boolean> = {
    [OnboardingRoutes.NAME]: true,
    [OnboardingRoutes.RESCUE]: true,
    [OnboardingRoutes.AGE]: true,
    [OnboardingRoutes.HAS_ARRIVED]: true,
    [OnboardingRoutes.FIRST_DAY_AT_HOME]: false,
    [OnboardingRoutes.ARRIVAL]: false,
    [OnboardingRoutes.GENDER]: true,
    [OnboardingRoutes.BREED]: true,
    [OnboardingRoutes.OWNER_NAME]: true,
    [OnboardingRoutes.NEEDS]: true,
    [OnboardingRoutes.CARE_NEEDS]: true,
    [OnboardingRoutes.NOTIFICATIONS]: true,
    [OnboardingRoutes.PURINA_OPT_IN]: true,
};

// Nourish constants
export const ONBOARDING_ROUTES_PROGRESS_NOURISH_AB: Record<string, number> = {
    [OnboardingRoutes.NAME]: 2,
    [OnboardingRoutes.RESCUE]: 3,
    [OnboardingRoutes.AGE]: 4,
    [OnboardingRoutes.HAS_ARRIVED]: 5,
    [OnboardingRoutes.FIRST_DAY_AT_HOME]: 6,
    [OnboardingRoutes.ARRIVAL]: 6,
    [OnboardingRoutes.GENDER]: 7,
    [OnboardingRoutes.BREED]: 8,
    [OnboardingRoutes.NOURISH]: 8, // There is no progress bar on the Nourish page / it doesn't affect progress
    [OnboardingRoutes.OWNER_NAME]: 9,
    [OnboardingRoutes.NEEDS]: 10,
    [OnboardingRoutes.CARE_NEEDS]: 11,
    [OnboardingRoutes.NOTIFICATIONS]: 12,
    [OnboardingRoutes.MARKETING_OPT_IN]: 13,
};

export const ONBOARDING_ROUTES_CHECKPOINTS_NOURISH_AB: Record<string, boolean> = {
    [OnboardingRoutes.NAME]: true,
    [OnboardingRoutes.RESCUE]: true,
    [OnboardingRoutes.AGE]: true,
    [OnboardingRoutes.HAS_ARRIVED]: true,
    [OnboardingRoutes.FIRST_DAY_AT_HOME]: false,
    [OnboardingRoutes.ARRIVAL]: false,
    [OnboardingRoutes.GENDER]: true,
    [OnboardingRoutes.BREED]: true,
    [OnboardingRoutes.NOURISH]: true,
    [OnboardingRoutes.OWNER_NAME]: true,
    [OnboardingRoutes.NEEDS]: true,
    [OnboardingRoutes.CARE_NEEDS]: true,
    [OnboardingRoutes.NOTIFICATIONS]: true,
    [OnboardingRoutes.MARKETING_OPT_IN]: false,
};

export const ONBOARDING_ROUTES_PROGRESS_US_NOURISH_AB: Record<string, number> = {
    [OnboardingRoutes.NAME]: 2,
    [OnboardingRoutes.RESCUE]: 3,
    [OnboardingRoutes.AGE]: 4,
    [OnboardingRoutes.HAS_ARRIVED]: 5,
    [OnboardingRoutes.FIRST_DAY_AT_HOME]: 6,
    [OnboardingRoutes.ARRIVAL]: 6,
    [OnboardingRoutes.GENDER]: 7,
    [OnboardingRoutes.BREED]: 8,
    [OnboardingRoutes.NOURISH]: 8, // There is no progress bar on the Nourish page / it doesn't affect progress
    [OnboardingRoutes.OWNER_NAME]: 9,
    [OnboardingRoutes.NEEDS]: 10,
    [OnboardingRoutes.CARE_NEEDS]: 11,
    [OnboardingRoutes.NOTIFICATIONS]: 12,
    [OnboardingRoutes.PURINA_OPT_IN]: 13,
};

export const ONBOARDING_ROUTES_CHECKPOINTS_US_NOURISH_AB: Record<string, boolean> = {
    [OnboardingRoutes.NAME]: true,
    [OnboardingRoutes.RESCUE]: true,
    [OnboardingRoutes.AGE]: true,
    [OnboardingRoutes.HAS_ARRIVED]: true,
    [OnboardingRoutes.FIRST_DAY_AT_HOME]: false,
    [OnboardingRoutes.ARRIVAL]: false,
    [OnboardingRoutes.GENDER]: true,
    [OnboardingRoutes.BREED]: true,
    [OnboardingRoutes.NOURISH]: true,
    [OnboardingRoutes.OWNER_NAME]: true,
    [OnboardingRoutes.NEEDS]: true,
    [OnboardingRoutes.CARE_NEEDS]: true,
    [OnboardingRoutes.NOTIFICATIONS]: true,
    [OnboardingRoutes.PURINA_OPT_IN]: true,
};
