import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { closeNourishModal } from './store/nourish-modal.actions';

@Component({
    standalone: true,
    selector: 'app-nourish-modal-base',
    template: '',
})
export class NourishModalBaseComponent {
    protected readonly store = inject(Store);

    protected readonly Color = Color;

    public onCloseClick(): void {
        this.store.dispatch(closeNourishModal());
    }
}
