<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            title="Notifications"
            background="var(--ion-color-app-background)"
            [leftButton]="{ analyticsIdentifier: 'notifications-back-btn', background: 'transparent' }"
            [rightButton]="false"
            (leftButtonPressed)="previousPage()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="notifications-page-ion-content page-ion-content ion-text-left" [scrollY]="true" [fullscreen]="true">
    <div class="page-content ion-justify-content-start">
        <h3>Training Reminders</h3>

        @if (notifications().length) { @for (notification of notifications(); let index = $index; track index) {
        <ion-item class="notification-card">
            <div>
                <h2>
                    {{ notification.hour < 10 ? '0' + notification.hour : notification.hour }}:{{ notification.minute <
                    10 ? '0' + notification.minute : notification.minute }}
                </h2>
                <p>{{ notification.weekdaysString.join(', ') }}</p>
                <button (click)="clearTrainingReminder(notification.notificationIds)">
                    <i class="fa fa-regular fa-trash-can"></i>
                </button>
            </div>
        </ion-item>
        } } @else {
        <p class="ion-text-center" data-test="notifications-page-no-notifications">
            There are no training reminders set at the moment
        </p>
        }

        <ion-button
            class="notifications-page-content__btn"
            appTrackClick
            identifier="notifications-page-content-btn"
            expand="block"
            data-test="notifications-page-add-notification-btn"
            [color]="Color.Max"
            (click)="setTrainingReminder()"
        >
            Set training reminder
        </ion-button>

        <h3>More</h3>
        <p
            style="text-align: left"
            data-test="notifications-page-manage-notification-btn"
            (click)="openApplicationDetails()"
        >
            Manage push notifications <i class="fa fa-chevron-right" style="float: right; padding-top: 4px"></i>
        </p>
    </div>
</ion-content>
