import { NgStyle } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ElementRerenderDirective, SvgColorComponent } from '@frontend/ui';
import { IonButton, IonCard, IonProgressBar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { CourseType } from '@shared/content-api-interface';
import { Color } from '@shared/utils/typescript';
import { CourseWithProgress } from '../../../courses/models/courses.models';
import { CourseStatus } from '../../../store/course/course.model';
import { goToCourseFromTile } from '../../data-access/today.page.actions';

@Component({
    selector: 'app-course-progress-tile',
    templateUrl: './course-progress-tile.component.html',
    styleUrl: './course-progress-tile.component.scss',
    standalone: true,
    imports: [
        NgStyle,
        IonButton,
        IonCard,
        IonProgressBar,
        AnalyticsTrackClickDirective,
        ElementRerenderDirective,
        SvgColorComponent,
    ],
})
export class CourseProgressTileComponent {
    private readonly store = inject(Store);

    protected readonly Color = Color;
    protected readonly CourseStatus = CourseStatus;
    protected readonly CourseType = CourseType;

    public course = input.required<CourseWithProgress>();

    public progressBarValue = computed(() => {
        const stepCompleted = this.course().numberOfUniqueStepsCompletedToday;

        if (stepCompleted < 3 && stepCompleted > 0) {
            return 0.33 * stepCompleted;
        }

        if (stepCompleted >= 3) {
            return 1;
        }

        return 0.16;
    });

    public onGoToCourseClick(): void {
        this.store.dispatch(
            goToCourseFromTile({
                courseId: this.course().id,
            }),
        );
    }
}
