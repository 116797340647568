<ion-content [color]="Color.AppBackground">
    @if (product()) {
    <ion-header class="page-header ion-no-border">
        <ion-toolbar>
            <app-nourish-product-header
                [logo]="product()!.logo"
                [squiggleSrc]="product()!.squiggleSrc"
                [colour]="product()!.colour"
            />
        </ion-toolbar>
    </ion-header>

    <main class="nourish-product-page__content ignore-global-color">
        <h2 class="nourish-product-page__title no-margin">{{ product()!.title | textReplacement }}</h2>

        <section class="nourish-product-page__section">
            <div class="nourish-product-perks">
                @for (perk of product()?.perks; track perk.text) {
                <div class="nourish-product-perk" [ngClass]="product()!.colour">
                    <i class="nourish-product-perk__icon" [ngClass]="perk.icon"></i>
                    <p class="nourish-product-perk__text no-margin s">{{ perk.text }}</p>
                </div>
                }
            </div>

            @if (product()!.discount) {
            <div class="nourish-product-discount" [ngClass]="Color.Max">
                <div class="nourish-product-discount__heading">
                    <i class="nourish-product-discount__icon fa-regular fa-gift"></i>
                    <span class="nourish-product-discount__amount">{{ product()!.discount!.amount }}% OFF</span>
                </div>
                <p class="nourish-product-discount__text no-margin s">
                    Use discount code
                    <span class="nourish-product-discount__code" [ngClass]="Color.Bailey"
                        >{{ product()!.discount!.code }}</span
                    >
                    at checkout
                </p>
            </div>
            }
        </section>

        <section class="nourish-product-pitch" [ngClass]="Color.AppOutline">
            <h3 class="no-margin">Why choose {{ product()!.productName }}?</h3>
            <div class="nourish-product-pitch__content">
                @for (bullet of product()!.bulletPoints; track bullet.title) {
                <div class="nourish-product-pitch__bullet">
                    <img class="nourish-product-pitch__image" [src]="bullet.image.src" [alt]="bullet.image.alt" />
                    <div class="nourish-product-pitch__right">
                        <h3 class="nourish-product-pitch__title no-margin">{{ bullet.title }}</h3>
                        <p class="nourish-product-pitch__description no-margin s">
                            {{ bullet.description | textReplacement }}
                        </p>
                    </div>
                </div>
                }
            </div>
        </section>

        <section class="nourish-product-page__section">
            <h3 class="no-margin">What our coaches think?</h3>
            <div class="nourish-coach-card ignore-global-color" [ngClass]="Color.AppOutline">
                <div class="nourish-coach-card__heading">
                    <img
                        class="nourish-coach-card__image"
                        [src]="product()!.coach.image.src"
                        [alt]="product()!.coach.image.alt"
                    />
                    <p
                        class="nourish-coach-card__name no-margin s"
                        [ngStyle]="{
                                'color': 'var(--ion-color-' + product()!.colour + ')'
                            }"
                    >
                        {{ product()!.coach.name }}
                    </p>
                </div>
                <p
                    class="nourish-coach-card__comment no-margin s"
                    [ngStyle]="{
                        'color': 'var(--ion-color-' + product()!.colour + ')'
                    }"
                >
                    “{{ product()!.coach.comment }}”
                </p>
            </div>
        </section>
    </main>

    <ion-footer class="ion-text-center gradient-footer">
        <ion-button expand="block" data-test="nourish-product-cta-btn" [color]="Color.Max" (click)="onCtaClick()">
            {{ product()!.callToAction.text }}
        </ion-button>
    </ion-footer>
    }
</ion-content>
