import { Routes } from '@angular/router';
import { OnboardingRoutes } from './onboarding-routing.model';
import { OnboardingCreatingPlanPage } from './pages/creating-plan/creating-plan.page';
import { OnboardingQuestionAgePage } from './pages/question-age/question-age.page';
import { OnboardingQuestionArrivalPage } from './pages/question-arrival/question-arrival.page';
import { OnboardingQuestionBreedPage } from './pages/question-breed/question-breed.page';
import { OnboardingQuestionCareNeedsPage } from './pages/question-care-needs/question-care-needs.page';
import { OnboardingQuestionFirstDayAtHomePage } from './pages/question-first-day-at-home/question-first-day-at-home.page';
import { OnboardingQuestionGenderPage } from './pages/question-gender/question-gender.page';
import { OnboardingQuestionHasArrivedPage } from './pages/question-has-arrived/question-has-arrived.page';
import { OnboardingQuestionNamePage } from './pages/question-name/question-name.page';
import { OnboardingQuestionNeedsPage } from './pages/question-needs/question-needs.page';
import { OnboardingQuestionNotificationsPage } from './pages/question-notifications/question-notifications.page';
import { OnboardingQuestionOwnerNamePage } from './pages/question-owner-name/question-owner-name.page';
import { OnboardingQuestionPurinaOptInPage } from './pages/question-purina-opt-in/question-purina-opt-in.page';
import { OnboardingQuestionRescuePage } from './pages/question-rescue/question-rescue.page';
import { OnboardingQuestionMarketingOptInPage } from './pages/question-marketing-opt-in/question-marketing-opt-in.page';
import { OnboardingNourishSignpostPage } from './pages/nourish-signpost/nourish-signpost.page';

export const onboardingRoutes: Routes = [
    {
        path: '',
        redirectTo: OnboardingRoutes.NAME,
        pathMatch: 'full',
    },
    {
        path: OnboardingRoutes.AGE,
        component: OnboardingQuestionAgePage,
    },
    {
        path: OnboardingRoutes.ARRIVAL,
        component: OnboardingQuestionArrivalPage,
    },
    {
        path: OnboardingRoutes.FIRST_DAY_AT_HOME,
        component: OnboardingQuestionFirstDayAtHomePage,
    },
    {
        path: OnboardingRoutes.GENDER,
        component: OnboardingQuestionGenderPage,
    },
    {
        path: OnboardingRoutes.HAS_ARRIVED,
        component: OnboardingQuestionHasArrivedPage,
    },
    {
        path: OnboardingRoutes.BREED,
        component: OnboardingQuestionBreedPage,
    },
    {
        path: OnboardingRoutes.NAME,
        component: OnboardingQuestionNamePage,
    },
    {
        path: OnboardingRoutes.NOURISH,
        component: OnboardingNourishSignpostPage,
    },
    {
        path: OnboardingRoutes.NEEDS,
        component: OnboardingQuestionNeedsPage,
    },
    {
        path: OnboardingRoutes.CARE_NEEDS,
        component: OnboardingQuestionCareNeedsPage,
    },
    {
        path: OnboardingRoutes.NOTIFICATIONS,
        component: OnboardingQuestionNotificationsPage,
    },
    {
        path: OnboardingRoutes.OWNER_NAME,
        component: OnboardingQuestionOwnerNamePage,
    },
    {
        path: OnboardingRoutes.MARKETING_OPT_IN,
        component: OnboardingQuestionMarketingOptInPage,
    },
    {
        path: OnboardingRoutes.PURINA_OPT_IN,
        component: OnboardingQuestionPurinaOptInPage,
    },
    {
        path: OnboardingRoutes.RESCUE,
        component: OnboardingQuestionRescuePage,
    },
    {
        path: OnboardingRoutes.CREATING_PLAN,
        component: OnboardingCreatingPlanPage,
    },
];
