import { differenceInMonths, isSameDay } from 'date-fns';
import { DismissibleInfoItem, ShowModalFrequency } from '../models/dismissible-info.model';

export const getDismissedItemById = (
    dismissedItems: DismissibleInfoItem[],
    itemId: string,
): DismissibleInfoItem | undefined => dismissedItems.find((dismissedItem) => dismissedItem.id === itemId);

export const isItemDismissed = (dismissedItems: DismissibleInfoItem[], itemId: string): boolean =>
    dismissedItems.some((dismissedItem) => dismissedItem.id === itemId);

export const shouldDisplayModal = (
    showModalFrequency: ShowModalFrequency,
    itemId: string,
    dismissedItems: DismissibleInfoItem[],
    today: Date,
): boolean => {
    switch (showModalFrequency) {
        case ShowModalFrequency.ONCE: {
            return dismissedItems.every((dismissedItem) => dismissedItem.id !== itemId);
        }
        case ShowModalFrequency.DAILY: {
            return dismissedItems.every((dismissedItem) => {
                return (
                    dismissedItem.id !== itemId ||
                    (dismissedItem.id === itemId &&
                        dismissedItem.dontShowAgain !== true &&
                        !isSameDay(new Date(dismissedItem.timestamp), today))
                );
            });
        }
        case ShowModalFrequency.MONTHLY: {
            return dismissedItems.every((dismissedItem) => {
                return (
                    dismissedItem.id !== itemId ||
                    (dismissedItem.id === itemId &&
                        dismissedItem.dontShowAgain !== true &&
                        differenceInMonths(today, new Date(dismissedItem.timestamp)) > 1)
                );
            });
        }
    }
};
