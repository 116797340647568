<button
    class="nourish-tile as-div"
    [ngStyle]="{
        'background-color': 'var(--ion-color-' + colour() + '-secondary-light)',
        'border-color': 'var(--ion-color-' + colour() + ')',
    }"
    (click)="onNourishTileClicked()"
>
    <h3
        class="nourish-tile__pill"
        [ngStyle]="{
            'background-color': 'var(--ion-color-' + colour() + ')',
        }"
    >
        {{ pill() }}
    </h3>

    <h3 class="nourish-tile__title">
        {{ title() }}
    </h3>

    <img class="nourish-tile__image" [src]="image().src" [alt]="image().alt" />
</button>
