import { selectIsInputOpenOnAndroid } from '@frontend/data-access/keyboard';
import { selectUrl } from '@frontend/data-access/router';
import { selectIsNourish, selectShowFoodCalculator } from '@frontend/data-access/user/config-cat';
import { createSelector } from '@ngrx/store';
import { selectIsRouteChildRoute } from '../../store/router/router.selectors';
import { todayPageFeature } from '../../today/data-access/today.page.reducer';
import {
    coursesTab,
    foodCalculatorTab,
    libraryTab,
    nourishTab,
    supportTab,
    TabRoute_FOOD_CALCULATOR,
    TabRoute_NOURISH,
    todayTab,
} from '../tabs.constants';
import { dismissibleInfoFeature, isItemDismissed } from '@frontend/data-access/dismissible-info';
import { TabButton } from '@frontend/ui';
import { NOURISH_TAB_INDICATOR_ID } from '../../nourish/constants/nourish.constants';

export const selectShouldHideTabsOnAndroid = createSelector(
    selectIsInputOpenOnAndroid,
    selectUrl,
    (isInputFocusedOnAndroid, currentUrl) => {
        const routesToHideTabOn = ['/main/food-calculator', '/main/library'];
        return isInputFocusedOnAndroid && routesToHideTabOn.includes(currentUrl);
    },
);

export const selectTabsVm = createSelector(
    selectIsRouteChildRoute,
    selectShowFoodCalculator,
    selectShouldHideTabsOnAndroid,
    todayPageFeature.selectShowAiChatView,
    selectIsNourish,
    dismissibleInfoFeature.selectDismissedInfoItems,
    (isChildRoute, showFoodCalculator, shouldHideTabsOnAndroid, showAiChatView, isNourish, dismissedItems) => {
        const tabButtons: TabButton[] = [todayTab, coursesTab, libraryTab];

        if (isNourish) {
            tabButtons.push(nourishTab);
        } else {
            tabButtons.push(foodCalculatorTab);
        }

        tabButtons.push(supportTab);

        const mappedTabButtons = tabButtons.map((tabButton) => {
            if (tabButton.tab === TabRoute_FOOD_CALCULATOR) {
                return {
                    ...tabButton,
                    isHidden: !showFoodCalculator,
                };
            }

            //todo LB: add test for when the nourish indicator is in dismissedItems

            if (tabButton.tab === TabRoute_NOURISH) {
                return {
                    ...tabButton,
                    shouldShowIndicator: !isItemDismissed(dismissedItems, NOURISH_TAB_INDICATOR_ID),
                };
            }

            return tabButton;
        });

        return {
            hideTabs: isChildRoute || shouldHideTabsOnAndroid || showAiChatView,
            // TODO: NBSon - The tabs below do not correctly show as highlighted when navigating directly via URL, possibly due to how the class ('tab-selected') is applied before being rendered, we should look at storing currentTab in state
            tabButtons: mappedTabButtons,
            dismissedItems,
        };
    },
);
