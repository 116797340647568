<ion-content class="when-and-how-to-switch-food" [color]="Color.Lola">
    <button
        class="xl when-and-how-to-switch-food__close-button"
        appTrackClick
        identifier="nourish-when-and-how-to-switch-food-modal-close-btn"
        (click)="onCloseClick()"
    >
        <i class="fa-light fa-xmark"></i>
    </button>
    <article class="when-and-how-to-switch-food__article page-padding-sides page-padding-bottom ignore-global-color">
        <h2 class="no-margin-bottom">When and how to switch to adult food?</h2>

        <p class="no-margin">Transitioning your pup to adult food is a key milestone. Here’s how to do it right:</p>

        <h3 class="when-and-how-to-switch-food__subheading no-margin">When to switch</h3>
        <section class="when-and-how-to-switch-food__highlighted-text" [ngClass]="Color.Charly">
            <p class="no-margin">
                <b>Small/Medium Breeds</b>
            </p>
            <p class="no-margin">Around 12 months old when they reach adult size</p>
        </section>
        <section class="when-and-how-to-switch-food__highlighted-text" [ngClass]="Color.Charly">
            <p class="no-margin">
                <b>Large/Giant Breeds</b>
            </p>
            <p class="no-margin">Between 18-24 months as their growth phase is longer</p>
        </section>

        <img
            class="when-and-how-to-switch-food__image"
            src="/assets/images/oliver/oliver-licks-food-from-floor.svg"
            alt="Oliver on all fours sticking his tongue out to lick food from the floor"
        />

        <h3 class="when-and-how-to-switch-food__subheading no-margin">How to switch</h3>
        <section>
            <ul class="when-and-how-to-switch-food__list">
                <li>
                    <p class="when-and-how-to-switch-food__text">
                        <b>Start Gradually</b><br />
                        Over 7-10 days, mix 25% adult food with 75% current food and increase the new food daily
                    </p>
                </li>
                <br />

                <li>
                    <p class="when-and-how-to-switch-food__text">
                        <b>Watch for reactions</b><br />
                        Monitor stool consistency and behaviour for signs of discomfort
                    </p>
                </li>
                <br />

                <li>
                    <p class="when-and-how-to-switch-food__text">
                        <b>Vet guidance</b><br />
                        Consult your vet for personalised recommendations based on your dog's breed, size, and activity
                        level.
                    </p>
                </li>
            </ul>
        </section>

        <app-nourish-product-tiles [productTiles]="productTiles" [correlationId]="'modal'" />
    </article>
</ion-content>
