import { Component, computed, effect, inject, OnInit } from '@angular/core';
import {
    IonButton,
    IonCard,
    IonContent,
    IonFooter,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonToolbar,
} from '@ionic/angular/standalone';
import { Color, mapDateOfBirthToAgeInWeeks } from '@shared/utils/typescript';
import { HeaderComponent } from '@frontend/feature/header';
import { routeToPrevious } from '@frontend/data-access/router';
import { Store } from '@ngrx/store';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { DatePipe } from '@angular/common';
import { FoodProductSelectorComponent } from './food-product-selector/food-product-selector.component';
import { InvalidInputErrorMessageComponent } from '@frontend/ui';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { WeightSelectorComponent } from './old/food-calculator-form-old/weight-selector/weight-selector.component';
import { openInfoDialog } from '../../store/info-dialog/info-dialog.actions';
import { foodCalculatorExpectedWeightInfoProps } from '../data-access/store/food-calculator-info-dialog-props';
import { householdFeature } from '@frontend/data-access/user/household';
import { selectFoodPortionQueryWithAgeInWeeks } from '../data-access/store/food-calculator.selectors';
import { foodCalculatorPageLoaded, foodPortionQueryUpdated } from '../data-access/store/food-calculator.actions';
import { foodCalculatorFeature } from '../data-access/store/food-calculator.reducer';

@Component({
    selector: 'app-food-calculator',
    templateUrl: './food-portion-calculator.page.html',
    styleUrl: './food-portion-calculator.page.scss',
    standalone: true,
    imports: [
        IonContent,
        HeaderComponent,
        IonHeader,
        IonToolbar,
        HeaderComponent,
        AnalyticsTrackClickDirective,
        DatePipe,
        FoodProductSelectorComponent,
        InvalidInputErrorMessageComponent,
        IonCard,
        IonInput,
        IonItem,
        IonLabel,
        ReactiveFormsModule,
        TextReplacementPipe,
        WeightSelectorComponent,
        IonButton,
        IonFooter,
    ],
})
export class FoodPortionCalculatorPage implements OnInit {
    protected readonly Color = Color;

    private readonly store = inject(Store);
    private formBuilder = inject(FormBuilder);
    private currentDog = this.store.selectSignal(householdFeature.selectCurrentDog);
    private foodPortionQuery = this.store.selectSignal(selectFoodPortionQueryWithAgeInWeeks);

    public minAgeInWeeks = 7;
    public minExpectedWeight = 1;

    public foodPortionLastCalculated = this.store.selectSignal(foodCalculatorFeature.selectFoodPortionLastCalculated);
    public ageInWeeks = computed(() => {
        return mapDateOfBirthToAgeInWeeks(this.currentDog()?.dateOfBirth);
    });

    public foodForm = this.formBuilder.group({
        foodProductId: new FormControl('', Validators.required),
        currentWeight: new FormControl(0, Validators.required),
        expectedWeight: new FormControl(0, [Validators.required, Validators.min(1)]),
        ageInWeeks: new FormControl(this.ageInWeeks(), [Validators.required, Validators.min(this.minAgeInWeeks)]),
    });

    constructor() {
        effect(() => {
            if (this.foodPortionQuery() && this.foodPortionQuery()!.foodProductId) {
                this.foodForm.patchValue({
                    ...this.foodPortionQuery(),
                });
            } else {
                this.foodForm.patchValue({
                    foodProductId: null,
                    currentWeight: null,
                    expectedWeight: null,
                });
            }
        });
    }

    ngOnInit() {
        this.store.dispatch(foodCalculatorPageLoaded());
    }

    public routeToPrevious(): void {
        this.store.dispatch(routeToPrevious({}));
    }

    public showWeightInfoModal(): void {
        this.store.dispatch(openInfoDialog(foodCalculatorExpectedWeightInfoProps));
    }

    public calculateFoodPortion(): void {
        const { foodProductId, currentWeight, expectedWeight, ageInWeeks } = this.foodForm.value;

        this.store.dispatch(
            foodPortionQueryUpdated({
                foodPortionQuery: {
                    foodProductId: foodProductId ?? '',
                    currentWeight: currentWeight ?? 0,
                    expectedWeight: expectedWeight ?? 0,
                    ageInWeeks: ageInWeeks ?? 0,
                },
                redirectToNourish: true,
            }),
        );
    }
}
