import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject, Injectable } from '@angular/core';
import { nourishCalculateFoodButtonClicked, nourishTileClicked } from './nourish.actions';
import { dismissibleInfoFeature, dismissInfo, isItemDismissed } from '@frontend/data-access/dismissible-info';
import { NOURISH_TAB_INDICATOR_ID, NourishTileId } from '../constants/nourish.constants';
import { routeTo } from '@frontend/data-access/router';
import { filter, map } from 'rxjs/operators';
import { TabRoute_NOURISH } from '../../tabs/tabs.constants';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { tabClicked } from '../../tabs/store/tab.actions';
import { openNourishModal } from '../modals/store/nourish-modal.actions';

@Injectable()
export class NourishEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);

    routeToNourishFoodCalculator$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishCalculateFoodButtonClicked),
            map(() => {
                return routeTo({ commands: ['main', 'nourish', 'food-portion-calculator'] });
            }),
        );
    });

    routeToNourish$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishTileClicked),
            map(() => {
                return routeTo({ commands: ['main', 'nourish'] });
            }),
        );
    });

    dismissNourishTile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishCalculateFoodButtonClicked),
            concatLatestFrom(() => this.store.select(dismissibleInfoFeature.selectDismissedInfoItems)),
            filter(([, dismissedInfo]) => !isItemDismissed(dismissedInfo, NourishTileId.CALCULATE_FOOD)),
            map(() => {
                return dismissInfo({
                    id: NourishTileId.CALCULATE_FOOD,
                    dontShowAgain: true,
                });
            }),
        );
    });

    dismissNourishIndicator$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(tabClicked, nourishTileClicked),
            concatLatestFrom(() => this.store.select(dismissibleInfoFeature.selectDismissedInfoItems)),
            filter(
                ([{ id }, dismissedInfo]) =>
                    (id === TabRoute_NOURISH || id === NourishTileId.CALCULATE_FOOD) &&
                    !isItemDismissed(dismissedInfo, NOURISH_TAB_INDICATOR_ID),
            ),
            map(() => {
                return dismissInfo({
                    id: NOURISH_TAB_INDICATOR_ID,
                    dontShowAgain: true,
                });
            }),
        );
    });

    triggerOpenNourishModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishTileClicked),
            filter(({ id }) => id !== NourishTileId.CALCULATE_FOOD),
            map(({ id }) => {
                return openNourishModal({ id });
            }),
        );
    });
}
