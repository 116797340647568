import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { IonContent } from '@ionic/angular/standalone';
import { NourishModalBaseComponent } from '../nourish-modal-base.component';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';

@Component({
    standalone: true,
    selector: 'app-how-much-exercise-is-enough-modal',
    templateUrl: './how-much-exercise-is-enough-modal.component.html',
    styleUrl: './how-much-exercise-is-enough-modal.component.scss',
    imports: [NgClass, IonContent, AnalyticsTrackClickDirective],
})
export class HowMuchExerciseModalComponent extends NourishModalBaseComponent {}
