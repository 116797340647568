<div class="progress-streak-modal" data-test="progress-streak-modal" [ngStyle]="dynamicallyAppliedStyles()">
    <ion-header class="page-header ion-no-border">
        <ion-toolbar>
            <app-header
                [leftButton]="false"
                [rightButton]="{
                    analyticsIdentifier: 'progress-streak-modal-close-btn',
                    sprite: 'light',
                    iconName: 'xmark',
                    iconColour: headerButtonIconColor(),
                    background: headerButtonBackgroundColor(),
                }"
                (rightButtonPressed)="closeModal()"
            />
        </ion-toolbar>
    </ion-header>

    <section class="progress-streak-modal__content">
        <div class="progress-streak-modal__title-container">
            <h1 class="progress-streak-modal__streak-length xxxl">{{ courseStreak().length }}</h1>
            <h1 class="progress-streak-modal__streak-text">day streak</h1>
        </div>

        <div class="progress-streak-modal__bottom-content-container ignore-global-color">
            <h1 class="ion-text-center progress-streak-modal__subtitle">
                {{ handIcon() }} - paw for completing 3 lessons of {{ courseStreak().title }}!
            </h1>

            <app-progress-streak [shouldShowDate]="false" class="progress-streak-modal__streak-container" />

            <p class="progress-streak-modal__compliment ion-text-center">
                {{ getComplimentString() | textReplacement }}
            </p>
        </div>
    </section>

    <ion-footer class="page-padding-bottom">
        <ion-button
            class="primary-btn ion-padding-horizontal"
            appTrackClick
            identifier="progress-streak-modal-got-it-btn"
            expand="block"
            data-test="progress-streak-modal-got-it-btn"
            [color]="buttonColor()"
            (click)="closeModal()"
            >Continue
        </ion-button>
    </ion-footer>
</div>
