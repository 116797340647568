import { AsyncPipe, NgClass } from '@angular/common';
import { Component, forwardRef, inject, input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonItem, IonLabel, IonText } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { filter, first } from 'rxjs/operators';
import { FoodProductSelectorDirective } from './food-product-selector.directive';
import { FoodProduct } from '../../data-access/food-calculator.model';
import { foodCalculatorFeature } from '../../data-access/store/food-calculator.reducer';

@Component({
    selector: 'app-food-product-selector',
    templateUrl: './food-product-selector.component.html',
    styleUrls: ['./food-product-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => FoodProductSelectorComponent),
        },
    ],
    standalone: true,
    imports: [FoodProductSelectorDirective, AsyncPipe, IonLabel, IonItem, IonText, NgClass],
})
export class FoodProductSelectorComponent implements ControlValueAccessor {
    private readonly store = inject(Store);
    selectedFoodProduct?: FoodProduct;

    foodProducts$ = this.store
        .select(foodCalculatorFeature.selectFoodProducts)
        .pipe(filter((foodProducts) => !!foodProducts.length));

    public nourishForm = input(false);

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange: (value: string) => void = () => {};

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched: () => void = () => {};

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    writeValue(value: string): void {
        this.foodProducts$.pipe(first()).subscribe((foodProducts) => {
            this.selectedFoodProduct = foodProducts.find((fp) => fp.id === value);
        });
    }

    selectFoodProduct($event: FoodProduct) {
        this.selectedFoodProduct = $event;
        this.onTouched();
        this.onChange(this.selectedFoodProduct.id);
    }
}
