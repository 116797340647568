import { Routes } from '@angular/router';
import { FoodPortionCalculatorPage } from '../food-calculator/feature-food-calculator/food-portion-calculator.page';
import { NourishProductPage } from './pages/nourish-product/nourish-product.page';
import { NourishPage } from './pages/nourish/nourish.page';

export const nourishRoutes: Routes = [
    {
        path: '',
        component: NourishPage,
        pathMatch: 'full',
    },
    {
        path: 'food-portion-calculator',
        component: FoodPortionCalculatorPage,
    },
    {
        path: 'chat',
        loadChildren: () => import('../chat/chat.routes'),
    },
    {
        path: 'product/:productId',
        component: NourishProductPage,
    },
];

export default nourishRoutes;
