import { createAction, props } from '@ngrx/store';
import { FoodPortionQuery, FoodProduct } from '../food-calculator.model';
import { FOOD_CALCULATOR_ACTION_SOURCE } from './food-calculator.constants';

export const foodCalculatorPageLoaded = createAction(`${FOOD_CALCULATOR_ACTION_SOURCE} Page Loaded`);

export const foodPortionQueryUpdated = createAction(
    `${FOOD_CALCULATOR_ACTION_SOURCE} Food Portion Query Updated`,
    props<{ foodPortionQuery: FoodPortionQuery; redirectToNourish?: boolean }>(),
);

export const showFoodCalculatorDisclaimerModal = createAction(
    `${FOOD_CALCULATOR_ACTION_SOURCE} Show Food Calculator Disclaimer Modal`,
);

export const loadFoodProducts = createAction(`${FOOD_CALCULATOR_ACTION_SOURCE} Load Food Products`);
export const loadFoodProductsSuccess = createAction(
    `${FOOD_CALCULATOR_ACTION_SOURCE} Load Food Products Success`,
    props<{ foodProducts: FoodProduct[] }>(),
);
export const loadFoodProductsFailure = createAction(
    `${FOOD_CALCULATOR_ACTION_SOURCE} Load Food Products Failure`,
    props<{ error: Error }>(),
);

export const getFoodPortion = createAction(
    `${FOOD_CALCULATOR_ACTION_SOURCE} Get Food Portion`,
    props<{ foodPortionQuery: FoodPortionQuery }>(),
);
export const getFoodPortionSuccess = createAction(
    `${FOOD_CALCULATOR_ACTION_SOURCE} Get Food Portion Success`,
    props<{ amountPerDay: number; currentDate: Date }>(),
);
export const getFoodPortionFailure = createAction(
    `${FOOD_CALCULATOR_ACTION_SOURCE} Get Food Portion Failure`,
    props<{ error: Error; foodPortionQuery: FoodPortionQuery }>(),
);

export const checkExpectedWeight = createAction(`${FOOD_CALCULATOR_ACTION_SOURCE} Check Expected Weight`);

export const loadExpectedWeight = createAction(`${FOOD_CALCULATOR_ACTION_SOURCE} Load Expected Weight`);
export const loadExpectedWeightSuccess = createAction(
    `${FOOD_CALCULATOR_ACTION_SOURCE} Load Expected Weight Success`,
    props<{ expectedWeight: number }>(),
);
export const loadExpectedWeightFailure = createAction(
    `${FOOD_CALCULATOR_ACTION_SOURCE} Load Expected Weight Failure`,
    props<{ error: Error }>(),
);
