<ion-card class="option-selector-square" [ngClass]="background">
    <img class="option-selector-square__img" [src]="imageUrl" />
    <h3 class="option-selector-square__title" [ngStyle]="{ color: 'var(--ion-color-' + background + '-contrast)' }">
        {{ title }}
    </h3>
    @for (option of options; track option.id) {
        <ion-chip
            class="option-selector-square__chip"
            [ngClass]="{
                'option-selector-square__chip--selected': option.id === selected()?.id,
            }"
            [ngStyle]="{
                background:
                    option.id === selected()?.id
                        ? 'var(--ion-color-' + background + '-contrast)'
                        : 'var(--ion-color-' + background + '-tint)',
            }"
            (click)="optionClicked(option)"
        >
            <p
                class="as-div s"
                [ngStyle]="{
                    color:
                        option.id === selected()?.id
                            ? 'var(--ion-color-' + background + ')'
                            : 'var(--ion-color-' + background + '-contrast)',
                }"
            >
                {{ option.shortQuestion }}
            </p>
        </ion-chip>
    }
</ion-card>
