import { DomainPostUserWebCommand } from '../../../commands/domain-api/user/user/user-web/domain-post-user-web.command';

export class DomainPostUserWebCommandMock {
    private defaultValue: DomainPostUserWebCommand = {
        userId: 'uuid-1234',
        userIdGa4: 'userIdGa4-123',
    };

    constructor(overrides?: Partial<DomainPostUserWebCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): DomainPostUserWebCommand {
        return this.defaultValue;
    }
}
