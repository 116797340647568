import { Course, SeasonalTopic } from '@frontend/data-access/contentful';
import { Color } from '@shared/utils/typescript';
import { CourseWithProgress } from '../courses/models/courses.models';
import { NourishTile } from '../nourish/models/nourish.model';

export enum TileType {
    SEASONAL_TOPIC = 'Seasonal Topic',
    COURSE = 'Course',
    NOURISH = 'Nourish',
}

export interface TodayCourse extends Course {
    tileType: TileType.COURSE;
}

export interface TodayProgressCourse extends CourseWithProgress {
    tileType: TileType.COURSE;
}

export interface TodaySeasonalTopic extends SeasonalTopic {
    tileType: TileType.SEASONAL_TOPIC;
}

export interface TodayNourishTile extends NourishTile {
    tileType: TileType.NOURISH;
}

export type TodayRecommendedTile = TodayCourse | TodaySeasonalTopic | TodayProgressCourse | TodayNourishTile;

export type TodayRecommendedTiles = TodayRecommendedTile[];

export interface TodayGenericCardSquare {
    imageUrl: string;
    title: string;
    backgroundColor: Color;
    cardName: string;
    contentUnlocked: boolean;
    description?: string;
    isBasicPlan?: boolean;
}
