<ion-card
    class="course-tile tile"
    [ngStyle]="{
        '--flash-outline-color': 'var(--ion-color-' + course().color + '-secondary)',
    }"
    [color]="course().color"
    [attr.data-test]="'course-tile-' + course().id"
>
    @if (course().status !== CourseStatus.IN_PROGRESS) {
        <app-svg-color
            class="course-tile__squiggle"
            data-test="course-tile-squiggle"
            [color]="'var(--ion-color-' + course().color + '-secondary-light)'"
            [imagePath]="
                '/assets/images/squiggles/course-tile/course-tile-squiggle-' +
                (course().courseCardSquiggle ?? '1') +
                '.svg'
            "
        />
    }
    <div class="course-tile__inner">
        <h2 class="course-tile__title">{{ course().title }}</h2>
        <div>
            @if (course().status !== CourseStatus.IN_PROGRESS) {
                <p class="course-tile__description s">{{ course().introduction }}</p>
            } @else {
                <div [class]="'course-tile__middle-container course-tile__middle-container--' + course().color">
                    <div class="course-tile__progress-bar-container">
                        <div
                            class="course-tile__progress-bar-separator course-tile__progress-bar-separator--third"
                            [ngStyle]="{
                                'background-color': 'var(--ion-color-' + course().color + ')',
                            }"
                        ></div>
                        <div
                            class="course-tile__progress-bar-separator course-tile__progress-bar-separator--two-thirds"
                            [ngStyle]="{
                                'background-color': 'var(--ion-color-' + course().color + ')',
                            }"
                        ></div>
                        <ion-progress-bar
                            class="course-tile__progress-bar"
                            [ngStyle]="{
                                '--progress-background': 'var(--ion-color-' + course().color + '-secondary)',
                            }"
                            [value]="progressBarValue()"
                        />
                    </div>
                    <div class="course-tile__paw-container">
                        <div *appElementRerender="course().numberOfUniqueStepsCompletedToday">
                            <i
                                class="fa-solid fa-paw course-tile__progress-streak-icon"
                                [ngStyle]="{
                                    color:
                                        course().numberOfUniqueStepsCompletedToday >= 3
                                            ? 'var(--ion-color-' + course().color + '-secondary)'
                                            : 'white',
                                }"
                            ></i>
                        </div>
                        <span
                            class="course-tile__progress-streak-icon-text"
                            [ngStyle]="{
                                color:
                                    course().numberOfUniqueStepsCompletedToday >= 3
                                        ? 'white'
                                        : 'var(--ion-color-' + course().color + ')',
                            }"
                        >
                            {{ course().streakLength }}
                        </span>
                    </div>
                </div>
                <p class="course-tile__streak-description s">
                    {{
                        course().numberOfUniqueStepsCompletedToday < 3
                            ? "Complete today's lessons to earn a streak"
                            : 'Come back tomorrow for more lessons!'
                    }}
                </p>
            }
        </div>

        <ion-button
            class="course-tile__go-to-btn s"
            [ngStyle]="{
                '--color': course().color + '-contrast',
                '--border-color': course().color + '-contrast',
            }"
            appTrackClick
            identifier="course-tile-go-to-btn"
            size="small"
            fill="outline"
            data-test="course-tile-go-to-btn"
            (click)="onGoToCourseClick()"
        >
            Go to course
        </ion-button>
    </div>
</ion-card>
