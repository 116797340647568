import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { IonContent } from '@ionic/angular/standalone';
import { NourishModalBaseComponent } from '../nourish-modal-base.component';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';

@Component({
    standalone: true,
    selector: 'app-help-with-tummy-troubles-modal',
    templateUrl: './help-with-tummy-troubles-modal.component.html',
    styleUrl: './help-with-tummy-troubles-modal.component.scss',
    imports: [NgClass, IonContent, AnalyticsTrackClickDirective],
})
export class HelpWithTummyTroublesModalComponent extends NourishModalBaseComponent {}
