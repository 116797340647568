import { Component } from '@angular/core';
import { IonContent } from '@ionic/angular/standalone';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { NourishModalBaseComponent } from '../nourish-modal-base.component';
import { NgClass } from '@angular/common';

@Component({
    standalone: true,
    selector: 'app-prevent-diarrhoea-modal',
    templateUrl: './prevent-diarrhoea-modal.component.html',
    styleUrl: './prevent-diarrhoea-modal.component.scss',
    imports: [IonContent, AnalyticsTrackClickDirective, NgClass],
})
export class PreventDiarrhoeaModalComponent extends NourishModalBaseComponent {}
