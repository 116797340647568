import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, inject, viewChild } from '@angular/core';
import { chatFeature } from '@frontend/data-access/chat';
import { selectIsStartCourseAb } from '@frontend/data-access/user/config-cat';
import { householdFeature } from '@frontend/data-access/user/household';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { collapseAnimation, fadeInAnimation, GenericCardSquareComponent, SliderComponent } from '@frontend/ui';
import { IonContent, IonHeader, IonToolbar, ViewWillEnter } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { CourseType } from '@shared/content-api-interface';
import { Color } from '@shared/utils/typescript';
import { ArrivalTileComponent } from '../arrival/arrival-tile/arrival-tile.component';
import { selectAiChatSuggestedQuestionsCardData } from '../chat/ai-chat/chat-intro/store/ai-chat-intro.selectors';
import { AiChatHeaderComponent } from '../chat/ai-chat/header/ai-chat-header.component';
import { SuggestedAiChatQuestionsComponent } from '../chat/ai-chat/suggested-questions-prompt/suggested-ai-chat-questions.component';
import { SuggestedQuestionsPromptClicked } from '../chat/ai-chat/suggested-questions-prompt/suggested-ai-chat-questions.model';
import { routeToAiChat, routeToCoachChat } from '../chat/chat.actions';
import { showPaymentModal } from '../store/payment/store/payment.actions';
import { selectContentUnlocked, selectIsBasicPlan } from '../store/payment/store/payment.selectors';
import {
    aiChatBackButtonClicked,
    aiChatInputClicked,
    aiChatInputSubmitted,
    suggestedAiChatQuestionClicked,
} from './data-access/today.page.actions';
import { todayPageFeature } from './data-access/today.page.reducer';
import { TodayAiChatInputComponent } from './today-ai-chat-input/today-ai-chat-input.component';
import { CourseProgressTileComponent } from './today-courses/course-progress-tile/course-progress-tile.component';
import { CourseTileComponent } from './today-courses/course-tile/course-tile.component';
import { TodayDailyBitesComponent } from './today-daily-bites/today-daily-bites.component';
import { TodayDevelopmentalWindowComponent } from './today-developmental-window/today-developmental-window.component';
import { TodayPinnedTopicsComponent } from './today-pinned-topics/today-pinned-topics.component';
import { TopicTileComponent } from './today-topics/topic-tile/topic-tile.component';
import {
    TileType,
    TodayCourse,
    TodayNourishTile,
    TodayProgressCourse,
    TodayRecommendedTile,
    TodaySeasonalTopic,
} from './today.model';
import { selectTodayPageRecommendedTiles, selectTodayPageSupportTiles } from './today.page.selectors';
import { dismissibleInfoFeature, isItemDismissed } from '@frontend/data-access/dismissible-info';
import { NourishTileComponent } from '../nourish/nourish-tile/nourish-tile.component';

@Component({
    selector: 'app-today',
    templateUrl: './today.page.html',
    styleUrl: './today.page.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        IonContent,
        IonHeader,
        IonToolbar,
        AiChatHeaderComponent,
        AnalyticsTrackClickDirective,
        ArrivalTileComponent,
        CourseTileComponent,
        GenericCardSquareComponent,
        SliderComponent,
        SuggestedAiChatQuestionsComponent,
        TodayAiChatInputComponent,
        TodayDailyBitesComponent,
        TodayDevelopmentalWindowComponent,
        TodayPinnedTopicsComponent,
        TopicTileComponent,
        CourseProgressTileComponent,
        NourishTileComponent,
    ],
    animations: [fadeInAnimation(), collapseAnimation()],
})
export class TodayPage implements ViewWillEnter {
    // TODO: NBSon - re-evaluate member ordering and grouping since new angular, IT'S CHRISTMAS!
    public suggestedQuestionsRef = viewChild<SuggestedAiChatQuestionsComponent>('suggestedQuestionsPrompt');
    public aiChatInputRef = viewChild<TodayAiChatInputComponent>('aiChatInput');

    protected readonly Color = Color;
    protected readonly CourseType = CourseType;

    private readonly store = inject(Store);
    private readonly cdr = inject(ChangeDetectorRef);

    public contentUnlocked = this.store.selectSignal(selectContentUnlocked);
    public dogName = this.store.selectSignal(householdFeature.selectDogName);
    public isStreamChatBusyCleaningUp = this.store.selectSignal<boolean>(chatFeature.selectIsDestroying);
    public recommendedTiles = this.store.selectSignal(selectTodayPageRecommendedTiles);
    public showAiChatView = this.store.selectSignal<boolean>(todayPageFeature.selectShowAiChatView);
    public supportTiles = this.store.selectSignal(selectTodayPageSupportTiles);
    public suggestedQuestions = this.store.selectSignal(selectAiChatSuggestedQuestionsCardData);
    public isBasicPlan = this.store.selectSignal(selectIsBasicPlan);
    public isStartCourseAb = this.store.selectSignal(selectIsStartCourseAb);
    public dismissedItems = this.store.selectSignal(dismissibleInfoFeature.selectDismissedInfoItems);

    constructor() {
        // Clear the input when the view is hidden
        effect(() => {
            if (this.aiChatInputRef() && !this.showAiChatView()) {
                this.aiChatInputRef()!.clear();
            }
        });
    }

    public ionViewWillEnter(): void {
        this.cdr.markForCheck();
    }

    public onAiChatClicked(): void {
        if (!this.showAiChatView()) {
            this.store.dispatch(aiChatInputClicked());
        }
    }

    public onAiChatBackClicked(): void {
        this.store.dispatch(aiChatBackButtonClicked());
    }

    public onAiChatSubmitted(message: string): void {
        this.store.dispatch(
            aiChatInputSubmitted({
                message,
            }),
        );
    }

    public onAiChatPromptClicked({ optionId, option, card }: SuggestedQuestionsPromptClicked): void {
        if (!this.suggestedQuestionsRef() || !optionId || !option || !card) {
            return;
        }

        this.store.dispatch(
            suggestedAiChatQuestionClicked({
                optionId,
                option,
                card,
            }),
        );

        this.suggestedQuestionsRef()!.clearSelection();
    }

    public onCardClick(unlocked: boolean, cardName: string): void {
        if (this.isStreamChatBusyCleaningUp()) {
            return;
        }

        if (!unlocked) {
            this.store.dispatch(
                showPaymentModal({
                    trigger: cardName,
                }),
            );

            return;
        }

        switch (cardName) {
            case 'puppy-coach': {
                this.store.dispatch(routeToCoachChat());
                break;
            }
            case 'ziggy-ai': {
                this.store.dispatch(routeToAiChat({}));
                break;
            }
            default: {
                break;
            }
        }
    }

    public isTodaySeasonalTopic(content: TodayRecommendedTile): content is TodaySeasonalTopic {
        return content.tileType === TileType.SEASONAL_TOPIC;
    }

    public isTodayNourishTile(content: TodayRecommendedTile): content is TodayNourishTile {
        return content.tileType === TileType.NOURISH && !isItemDismissed(this.dismissedItems(), content.id);
    }

    // Non startCourseAb test tiles
    public isTodayCourse(content: TodayRecommendedTile): content is TodayCourse {
        return content.tileType === TileType.COURSE && !('numberOfUniqueStepsCompletedToday' in content);
    }

    // startCourseAb test tiles
    public isTodayProgressCourse(content: TodayRecommendedTile): content is TodayProgressCourse {
        return content.tileType === TileType.COURSE && 'numberOfUniqueStepsCompletedToday' in content;
    }
}
