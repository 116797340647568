import { Pipe, PipeTransform } from '@angular/core';
import { mapDateOfBirthToAgeString } from '@shared/utils/typescript';

@Pipe({
    name: 'age',
    standalone: true,
})
export class AgePipe implements PipeTransform {
    transform(
        dateOfBirth: string | Date | undefined,
        dateOfReference?: Date | undefined,
        shouldPluralise = true,
    ): string {
        return mapDateOfBirthToAgeString(dateOfBirth, dateOfReference, shouldPluralise);
    }
}
