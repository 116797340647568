export const v2UsersPaths = {
    householdsPath: 'v2/households',
    usersPath: 'v2/users',
    dogsPath: 'v2/dogs',
    partnerPath: 'v2/partner',
    trainerPath: 'v2/trainer',
    agentsPath: 'v2/agents',
    chatConversationsPath: 'v2/chat/conversations',
    chatMessagesPath: 'v2/chat/messages',
    chatTagsPath: 'v2/chat-tags',
    pinnedTopicsPath: 'v2/pinned-topics',
    courseProgressPath: 'v2/course-progress',
    rescheduledStepPath: 'v2/rescheduled-step',
    stepProgressPath: 'v2/step-progress',
    stepProgressTypePath: 'v2/step-progress-type',
    todayProgressPath: 'v2/today-progress',
    wooCommerceUsersPath: 'v2/woo-commerce-users',
    feedbackPath: 'v2/feedback',
    happinessCheckPath: 'v2/happiness-check',
    usersWebPath: 'v2/users/users-web',
};
