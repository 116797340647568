import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import {
    clearLocalNotification,
    clearLocalNotificationSuccess,
    getLocalNotifications,
    scheduleLocalNotification,
    scheduleLocalNotificationSuccess,
    selectLocalNotifications,
} from '@frontend/data-access/local-notification';
import { ModalService } from '@frontend/utility/modal';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Color } from '@shared/utils/typescript';
import { concatMap, filter, map, mergeMap, tap } from 'rxjs/operators';
import { openToast } from '../../store/toast/toast.actions';
import { TrainingReminderModalComponent } from '../components/training-reminder-modal/training-reminder-modal.component';
import { TRAINING_REMINDER_MODAL_ID } from '../training-reminder.constant';
import {
    closeTrainingReminderModal,
    createTrainingReminder,
    launchTrainingReminderModal,
    trainingReminderModalCloseClicked,
} from './training-reminder-modal.actions';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';

@Injectable()
export class TrainingReminderModalEffects {
    private readonly actions$ = inject(Actions);
    private readonly modalService = inject(ModalService);
    private store = inject(Store);

    showTrainingReminderModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(launchTrainingReminderModal),
            tap(({ dogName }) => {
                return this.modalService.showModal({
                    id: TRAINING_REMINDER_MODAL_ID,
                    component: TrainingReminderModalComponent,
                    componentProps: {
                        dogName,
                    },
                    cssClass: ['modal', 'modal-training-reminder'],
                });
            }),
            map(({ trigger }) => {
                return trackEvent({
                    eventName: '[Training Reminder] Show Modal',
                    eventProperties: {
                        trigger,
                    },
                });
            }),
        );
    });

    closeTrainingReminderModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(closeTrainingReminderModal),
                tap(() => {
                    return this.modalService.dismissById(TRAINING_REMINDER_MODAL_ID);
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    trainingReminderModalCloseClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trainingReminderModalCloseClicked),
            mergeMap(() => {
                return [
                    closeTrainingReminderModal(),
                    trackEvent({
                        eventName: `[Training Reminder] Close Button Clicked`,
                    }),
                ];
            }),
        );
    });

    createTrainingReminder$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createTrainingReminder),
            filter(({ weekdays }) => weekdays.length > 0), // Extra check to prevent creating notifications with no days
            concatLatestFrom(() => this.store.select(selectLocalNotifications)),
            concatMap(([{ dogName, time, weekdays }, currentNotifications]) => {
                const actions: Action[] = [];

                //find current notification
                const sameNotification = currentNotifications.find(
                    (notification) =>
                        notification.hour === time.getHours() && notification.minute === time.getMinutes(),
                );

                let newWeekdays = [...weekdays];

                //if exists, extract out current weekdays
                if (sameNotification) {
                    //combine with the new weekdays and order them numerically
                    newWeekdays = [...new Set([...weekdays, ...sameNotification.weekdays])].sort();

                    //delete the existing notification record
                    actions.push(
                        clearLocalNotification({
                            notifications: sameNotification.notificationIds.map((id) => {
                                return { id };
                            }),
                        }),
                    );
                }

                //create new record with ordered weekdays
                const notifications = newWeekdays.map((weekday) => {
                    // Generate a positive 32-bit JAVA integer
                    const min = 1;
                    const max = 2147483647;
                    const id = Math.floor(Math.random() * (max - min + 1) + min);

                    return {
                        id,
                        title: `${dogName}'s training reminder`,
                        body: `It's time to train ${dogName}.`,
                        schedule: {
                            on: {
                                weekday,
                                hour: time.getHours(),
                                minute: time.getMinutes(),
                                second: time.getSeconds(),
                            },
                        },
                    };
                });

                actions.push(scheduleLocalNotification({ notifications }));
                return actions;
            }),
        );
    });

    handleTrainingReminderCreatedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(scheduleLocalNotificationSuccess),
            mergeMap(() => {
                return [
                    closeTrainingReminderModal(),
                    openToast({
                        message: 'Training reminder has been set',
                        color: Color.Luna,
                    }),
                    trackEvent({
                        eventName: '[Training Reminder] Training Reminder Created',
                    }),
                    getLocalNotifications(),
                ];
            }),
        );
    });

    handleClearLocalNotificationSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(clearLocalNotificationSuccess),
            map(() => {
                return openToast({ message: 'Training reminder has been removed', color: Color.Luna });
            }),
        );
    });
}
