<section class="nourish-header page-padding-top page-padding-sides">
    <h1 class="page-title no-margin-bottom" data-test="nourish-header-title">Nourish</h1>

    <section class="nourish-header__subtitle-block">
        <h2 class="no-margin-bottom no-margin-top" data-test="nourish-header-subtitle">{{ dogName() }}'s feeding</h2>
        @if (foodPortion()) {
            <ion-button
                class="s ignore-screen-based-font-scaling nourish-header__edit-button"
                size="small"
                fill="outline"
                data-test="nourish-header-edit-btn"
                (click)="goToFoodCalculator()"
                >Edit
            </ion-button>
        }
    </section>

    <section class="nourish-header__image" [class.nourish-header__image--faded]="!foodPortion()">
        @if (foodPortion()) {
            <div class="nourish-header__image-text">
                <h2>{{ foodPortion() }} g</h2>
                <p>Feed this across <b>3 meals a day</b></p>
            </div>
        } @else {
            <ion-button
                appTrackClick
                class="nourish-header__calculate-button s"
                identifier="nourish-calculate-button"
                expand="block"
                data-test="nourish-header-calculate-btn"
                [color]="Color.Max"
                (click)="calculateFoodPortionClicked()"
            >
                <i class="fa-solid fa-plus"></i>Calculate food portion
            </ion-button>
        }
    </section>

    @if (!foodPortion()) {
        <button
            class="nourish-header__disclaimer as-div"
            data-test="nourish-header-disclaimer-btn"
            (click)="showDisclaimerModal()"
        >
            Why can this be different from my packaging?&nbsp;<i class="fa-light fa-circle-info"></i>
        </button>
    }
</section>
