import { inject, Injectable } from '@angular/core';
import { routeTo } from '@frontend/data-access/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map } from 'rxjs/operators';
import { closeNourishModal } from '../../modals/store/nourish-modal.actions';
import { productTileClicked } from './nourish-product-tiles.actions';

@Injectable()
export class NourishProductTilesEffects {
    private actions$ = inject(Actions);

    navigateToProductPage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(productTileClicked),
            map(({ id }) => {
                return routeTo({
                    commands: ['main', 'nourish', 'product', id],
                });
            }),
        );
    });

    closeNourishModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(productTileClicked),
            filter(({ correlationId }) => correlationId === 'modal'),
            map(() => closeNourishModal()),
        );
    });
}
