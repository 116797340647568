import { Component } from '@angular/core';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ProgressHeaderComponent } from '@frontend/ui';
import { IonButton, IonContent, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Color } from '@shared/utils/typescript';
import { onboardingRouteTo } from '../../data-access/onboarding.actions';
import { OnboardingRoutes } from '../../onboarding-routing.model';
import { OnboardingQuestionBasePage } from '../question-base.page';

@Component({
    standalone: true,
    selector: 'app-onboarding-nourish-signpost-page',
    templateUrl: 'nourish-signpost.page.html',
    styleUrl: 'nourish-signpost.page.scss',
    imports: [
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonToolbar,
        AnalyticsTrackClickDirective,
        ProgressHeaderComponent,
        TextReplacementPipe,
    ],
})
export class OnboardingNourishSignpostPage extends OnboardingQuestionBasePage {
    protected readonly Color = Color;
    protected readonly bulletPoints = [
        'Calculate perfect portions for [NAME]’s breed and age',
        'Discover insights to keep [NAME]’s tummy happy and healthy',
        'Save money on food recommendations tailored to [NAME]’s nutritional needs',
    ];

    public onContinueClick(): void {
        this.store.dispatch(
            onboardingRouteTo({
                route: OnboardingRoutes.OWNER_NAME,
            }),
        );
    }
}
