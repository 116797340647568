import { NgStyle } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { routeToPrevious } from '@frontend/data-access/router';
import { HeaderButton, HeaderComponent } from '@frontend/feature/header';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';

@Component({
    selector: 'app-nourish-product-header',
    templateUrl: './nourish-product-header.component.html',
    styleUrl: './nourish-product-header.component.scss',
    standalone: true,
    imports: [NgStyle, HeaderComponent],
})
export class NourishProductHeaderComponent {
    private readonly store = inject(Store);

    public logo = input.required<{ src: string; alt: string }>();
    public squiggleSrc = input.required<string>();
    public colour = input.required<Color>();

    public computedStyles = computed(() => {
        return {
            background: `url("${this.squiggleSrc()}") no-repeat 50% 85%/100% var(--ion-color-${this.colour()}-secondary-light)`,
        };
    });

    protected readonly leftButtonConfig: HeaderButton = {
        analyticsIdentifier: 'nourish-product-back-btn',
        background: 'transparent',
    };

    public onBackClick(): void {
        this.store.dispatch(
            routeToPrevious({
                commands: ['main', 'nourish'],
            }),
        );
    }
}
