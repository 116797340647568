import { Component, computed } from '@angular/core';
import { IonButton, IonContent } from '@ionic/angular/standalone';
import { mapDateOfBirthToAgeInWeeks } from '@shared/utils/typescript';
import { householdFeature } from '@frontend/data-access/user/household';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { NourishModalBaseComponent } from '../nourish-modal-base.component';
import { routeTo } from '@frontend/data-access/router';

@Component({
    standalone: true,
    selector: 'app-how-to-weigh-your-dog-modal',
    templateUrl: './how-to-weigh-your-dog-modal.component.html',
    styleUrl: './how-to-weigh-your-dog-modal.component.scss',
    imports: [IonContent, AnalyticsTrackClickDirective, IonButton],
})
export class HowToWeighYourDogModalComponent extends NourishModalBaseComponent {
    private currentDog = this.store.selectSignal(householdFeature.selectCurrentDog);
    public dogName = this.store.selectSignal(householdFeature.selectDogName);
    public ageInWeeks = computed(() => {
        return mapDateOfBirthToAgeInWeeks(this.currentDog()?.dateOfBirth);
    });

    public goToFoodCalculator(): void {
        this.onCloseClick();
        this.store.dispatch(routeTo({ commands: ['main', 'nourish', 'food-portion-calculator'] }));
    }
}
