<div class="square-card">
    @if (!unlocked()) {
        @if (isBasicPlan()) {
            <app-generic-chip class="square-card__premium-chip" text="Premium" icon="fa-regular fa-crown" />
        } @else {
            <span>
                <i class="fa-solid fa-lock square-card__locked-icon" data-test="square-card__locked-icon"></i>
            </span>
        }
    }
    <ion-card class="square-card__outer-container" [ngClass]="background()">
        <div
            class="square-card__container"
            [ngClass]="{
                'square-card__container--locked': !unlocked(),
            }"
        >
            <img class="square-card__img" [src]="imageUrl()" />

            <h2 class="square-card__title" [ngStyle]="{ color: 'var(--ion-color-' + background() + '-contrast)' }">
                {{ title() }}
            </h2>

            @if (description()) {
                <p
                    class="square-card__description"
                    [ngStyle]="{ color: 'var(--ion-color-' + background() + '-contrast)' }"
                    data-test="generic-card-square-description"
                >
                    {{ description() }}
                </p>
            }
        </div>
    </ion-card>
</div>
