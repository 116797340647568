<ul class="courses-page-week-container">
    @for (day of daysOfWeek(); track $index) {
        <li class="courses-page-week-container__paw-and-day ignore-global-color">
            @if (shouldShowDate()) {
                <p class="courses-page-week-container__day s ion-text-center">
                    {{ day.date }}
                </p>
            }
            <p class="courses-page-week-container__day s ion-text-center">
                {{ day.label }}
            </p>
            @if (day.completed) {
                <div>
                    <i class="fa-paw fa-xl fa-solid"></i>
                </div>
            } @else {
                <div>
                    <i class="fa-paw fa-xl fa-light"></i>
                </div>
            }
        </li>
    }
</ul>
