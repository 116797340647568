<h3 class="no-margin">Our picks for {{ dateOfBirth() | age: undefined : false }} old {{ breedName() }}s</h3>

<app-slider [gap]="20" [lastItemPadding]="20">
    @for (tile of productTiles(); track tile.title) {
        <app-slider-item>
            <app-nourish-product-tile
                [title]="tile.title"
                [chip]="tile.chip"
                [image]="tile.image"
                [colour]="tile.colour"
                (click)="onClickProductTile(tile.id)"
            />
        </app-slider-item>
    }
</app-slider>
