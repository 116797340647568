import { Component, computed, inject, input } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectProgressStreakInfo } from '../data-access/progress-streak-modal.selectors';
import { selectSelectedCourseWithProgressInfo } from '../../../courses/course-path/store/course-path.selectors';

@Component({
    selector: 'app-progress-streak',
    templateUrl: './progress-streak.component.html',
    styleUrl: './progress-streak.component.scss',
    standalone: true,
})
export class ProgressStreakComponent {
    //todo LB: see if this can be moved to ui

    private readonly store = inject(Store);

    public shouldShowDate = input.required<boolean>();

    public allCourseStreaks = this.store.selectSignal(selectProgressStreakInfo);
    public selectedCourse = this.store.selectSignal(selectSelectedCourseWithProgressInfo);

    public courseStreak = computed(() => {
        const courseStreakFound = this.allCourseStreaks().find(
            (courseStreak) => courseStreak.id === this.selectedCourse()?.id,
        );

        if (!courseStreakFound) {
            return {
                length: 0,
                dateStarted: new Date(),
            };
        }

        return {
            length: courseStreakFound.length,
            dateStarted: courseStreakFound.dateStarted,
        };
    });

    public activeWeekStartDate = computed(() => {
        const today = new Date();
        const daysSinceStart = Math.floor(
            (today.getTime() - this.courseStreak().dateStarted.getTime()) / (1000 * 60 * 60 * 24),
        );
        const currentWeekIndex = Math.floor(daysSinceStart / 7);

        const weekStart = this.courseStreak().dateStarted;

        if (this.courseStreak().length > 7) {
            weekStart.setDate(weekStart.getDate() + currentWeekIndex * 7);
        }

        return weekStart;
    });

    public daysOfWeek = computed(() => {
        const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const startDayOfWeek = this.activeWeekStartDate().getDay();
        const reorderedDays = [...weekDays.slice(startDayOfWeek), ...weekDays.slice(0, startDayOfWeek)];

        const days = [];

        for (let i = 0; i < 7; i++) {
            const label = reorderedDays[i]; // Dynamically ordered day labels

            // Create a new Date object for each day, adding `i` days to the active week start date
            const date = new Date(this.activeWeekStartDate());
            date.setDate(date.getDate() + i);

            let isStreak = false;
            const streakIndex = this.courseStreak().length % 7;

            if (!this.courseStreak().length) {
                isStreak = false;
            }

            // If it's a full week, show a full week of streak, otherwise show the coming week
            // Add 1 because the index starts at 0
            else if (streakIndex === 0 || i + 1 <= streakIndex) {
                isStreak = true;
            }

            days.push({
                label,
                date: date.getDate(),
                completed: isStreak,
            });
        }

        return days;
    });
}
